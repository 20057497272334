// Dependencies
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useQuery } from "../../../hooks";

// Redux Dependencies
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";

import { selectCourse } from "../../../redux/coursesSlice";
import {
  selectCourseSubmissionsSortedByUserDueDate,
  selectTasks,
  fetchStudentCourseTasks,
  STATUS
} from "../../../redux/tasksSlice";
//Components
import SubmissionsListItem from "./SubmissionsListItem";
import PangeaSpinner from "../../SharedComponents/PangeaSpinner";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Divider
} from "@mui/material";

// Styles
const useStyles = makeStyles((theme) => ({
  tasksHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: theme.spacing(7.5),
    marginBottom: theme.spacing(5)
  },
  chip: { margin: "-8px" },

  active: {
    backgroundColor: "transparent",
    color: "#0eac72",
    border: "1px solid #0eac72"
  },
  pending: {
    backgroundColor: "transparent",
    border: "1px solid black"
  },
  delivered: {
    backgroundColor: "#0eac72"
  },
  graded: {
    backgroundColor: "#0eac72"
  },
  new: {
    border: "1px solid black"
  },
  left: {
    textAlign: "left"
  },
  right: {
    textAlign: "right"
  },
  submitted: {
    backgroundColor: "transparent",
    color: "#0eac72",
    border: "1px solid #0eac72"
  },
  createBtn: {
    borderRadius: "4px",
    valign: "bottom",
    position: "absolute",
    bottom: "0px",
    right: "0px"
  },
  container: {
    width: "100w",
    height: "100vh"
  },
  clickableTask: {
    cursor: "pointer",
    "&:focus": {
      backgroundColor: "rgba(46, 125, 50, 0.04)"
    }
  },
  newTask: {
    fontWeight: "800"
  },
  dialog: {
    backgroundColot: "red"
  },
  tableContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "24px",
    overflowX: "visible"
  }
}));

export default function TasksStudentView(props) {
  //Hooks
  const classes = useStyles();
  const selectedCourseId = useSelector(
    (state) => state.user.userProfile?.selectedCourseId
  );
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  // Ephemeral State
  // Redux State
  const course = useSelector((state) => selectCourse(state, selectedCourseId));
  const tasks = useSelector((state) => selectTasks(state, selectedCourseId));
  const submissions = useSelector((state) =>
    selectCourseSubmissionsSortedByUserDueDate(state, selectedCourseId)
  );
  const currentUserId = useSelector((state) => state.user.auth.uid);
  const status = useSelector((state) => state.tasks.status);

  // Behavior
  useEffect(() => {
    let sp = new URLSearchParams(location.search);
    let task_id = sp.get("task_id");
    if (task_id && course && tasks && submissions) {
      task_id = Number(task_id);
      if (task_id) {
        let submission = submissions.find((a) => a.task_id === task_id);
        if (submission) {
          navigate(`/task?submission_id=${submission.id}`);
        }
      }
    }
  }, [location, course, tasks, submissions, history]);

  useEffect(() => {
    dispatch(fetchStudentCourseTasks(selectedCourseId));
  }, [dispatch, selectedCourseId]);

  // render
  if (status === STATUS.PENDING) {
    return <PangeaSpinner />;
  } else
    return (
      <>
        <Box className={classes.tasksHeader}>
          <Typography variant="h5">{course.name}</Typography>
        </Box>
        <Divider />
        {tasks <= 0 ? (
          <Typography variant="h6">
            There are currently no tasks assigned for this course
          </Typography>
        ) : (
          <TableContainer className={classes.tableContainer}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography color="textSecondary">
                      <FormattedMessage
                        id="tasks.manager.task"
                        defaultMessage="Task"
                      />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary">
                      <FormattedMessage
                        id="tasks.manager.status"
                        defaultMessage="Status"
                      />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary">
                      <FormattedMessage
                        id="tasks.manager.feedback"
                        defaultMessage="Feedback"
                      />
                    </Typography>
                  </TableCell>
                  <TableCell width="180">
                    <Typography color="textSecondary">
                      <FormattedMessage
                        id="tasks.manager.submissionDate"
                        defaultMessage="Submission date"
                      />
                    </Typography>
                  </TableCell>
                  <TableCell width="180">
                    <Typography color="textSecondary">
                      <FormattedMessage
                        id="tasks.manager.deadline"
                        defaultMessage="Deadline"
                      />
                    </Typography>
                  </TableCell>
                  <TableCell aria-label="empty header"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {submissions.map(
                  (submission) =>
                    submission.owner === currentUserId && (
                      <SubmissionsListItem
                        key={submission.id}
                        submission={submission}
                      />
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>
    );
}

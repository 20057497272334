import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IconButton, Box, Typography, ButtonBase } from "@mui/material";

import DirectionalChevronIcon from "../SharedComponents/DirectionalChevronIcon";
import makeStyles from "@mui/styles/makeStyles";
import { captureException } from "../../utils/errorHandlers";
import {
  setQuestionIndex,
  setSelectedQuestionDisplayIndex
} from "../../redux/taskSlice";
import { updateTask } from "../../redux/firebaseMiddleware";
import { setSelectedInteractionId } from "../../redux/interactionsSlice";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { useQuery } from "../../hooks";
import { selectTextDirection } from "../../redux/firestoreSelectors";
import useSelectedQuestion from "../../hooks/firestore/useSelectedQuestion";

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    display: "flex",
    alignItems: "center"
  },
  indexContainer: {
    overflow: "hidden",
    flex: 1
  },
  carousel: {
    display: "flex",
    alignItems: "center"
  },
  carouselItem: {
    width: 48,
    height: 48,
    padding: 0,
    flexShrink: 0,
    marginLeft: "calc((25% - 48px) / 2)",
    marginRight: "calc((25% - 48px) / 2)",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  alignToStart: {
    marginRight: 0,
    marginLeft: theme.spacing(2)
  },
  selected: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main
  },
  number: {
    fontSize: 20,
    color: "inherit",
    transform: "translate(0px, -1px)"
  },
  carouselArrow: {
    padding: 0,
    color: "white",
    "&.Mui-disabled": {
      color: "white",
      opacity: 0.2
    }
  }
}));
function QuestionsCarousel({ questionsOrder = [], updateTaskFeedbackState }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const textDirection = useSelector((state) => selectTextDirection(state));
  let { submission_id, question_id } = useQuery();

  const selectedQuestionId = useSelector(
    (state) => state.interactions.selectedInteractionId
  );

  const selectedQuestionIndex = useSelectedQuestion();
  const displayIndex = useSelector(
    // TODO: refactor to use ID only and remove
    (state) => state.task.selectedQuestionDisplayIndex
  );
  const [lastVisibleIndex, setLastVisibleIndex] = useState(
    Math.max(Math.min(selectedQuestionIndex + 3, questionsOrder.length - 1), 3)
  );

  function getDisplayIndex() {
    // TODO: refactor to use ID only and remove
    const questions = questionsOrder.filter(
      (q) => q.id === selectedQuestionIndex
    );
    if (
      displayIndex !== undefined &&
      questions.length &&
      displayIndex < questionsOrder.length
    ) {
      return displayIndex;
    }
    return questions[0] ? questions[0].displayIndex : 0;
  }
  // TODO: get rid of this whole display index abomination

  useEffect(() => {
    if (!question_id) return;

    question_id = Number(question_id);

    question_id =
      !question_id || questionsOrder.length >= question_id ? question_id : 0;

    dispatch(setQuestionIndex(Number(question_id)));
    dispatch(setSelectedQuestionDisplayIndex(Number(question_id)));
    dispatch(setSelectedInteractionId(questionsOrder[question_id].id));
  }, [dispatch, question_id, questionsOrder]);

  useEffect(() => {
    if (selectedQuestionId || !questionsOrder.length) return;
    dispatch(setSelectedInteractionId(questionsOrder[0].id));
  }, [dispatch, questionsOrder, selectedQuestionId]);

  useEffect(() => {
    if (question_id) return;

    if (questionsOrder.length) {
      dispatch(setSelectedInteractionId(questionsOrder[0].id));
    }
    dispatch(setSelectedQuestionDisplayIndex(getDisplayIndex()));
    return () => {
      dispatch(setSelectedQuestionDisplayIndex(0));
    };
  }, []);

  useEffect(() => {
    if (question_id) return;
    // TODO: refactor to use ID only and remove
    dispatch(setSelectedQuestionDisplayIndex(getDisplayIndex()));
    // setDisplayIndex(d => getDisplayIndex(d));
  }, [questionsOrder, selectedQuestionIndex]);

  useEffect(() => {
    // this is hack due to a race condition , TODO: check this is still necessary
    setLastVisibleIndex(
      Math.max(Math.min(displayIndex + 3, questionsOrder.length - 1), 3)
    );
  }, [displayIndex]);

  function updateAnswerMode(index) {
    if (submission_id >= 0) {
      // this Carousel is in context of a teacher feedback.
      updateTaskFeedbackState(index);
    } else {
      // This Carousel is in context of a studant.
      dispatch(
        updateTask({
          selectedQuestion: index
        })
      );
    }
  }

  function BackwardBtn() {
    return (
      <IconButton
        className={clsx(classes.carouselArrow)}
        disabled={lastVisibleIndex - 3 <= 0}
        onClick={() => {
          setLastVisibleIndex(Math.max(lastVisibleIndex - 4, 3));
        }}
        aria-label={"Previous question"}
        size="large">
        <DirectionalChevronIcon reverse />
      </IconButton>
    );
  }

  function ForwardBtn() {
    return (
      <IconButton
        className={clsx(classes.carouselArrow)}
        disabled={lastVisibleIndex >= questionsOrder.length - 1}
        onClick={() =>
          setLastVisibleIndex(
            Math.min(lastVisibleIndex + 4, questionsOrder.length - 1)
          )
        }
        aria-label={"Next question"}
        size="large">
        <DirectionalChevronIcon />
      </IconButton>
    );
  }

  return (
    <Box
      className={classes.carouselContainer}
      sx={{
        margin: questionsOrder.length > 4 ? "4px" : "24px",
        marginTop: "24px",
        marginBottom: "16px"
      }}>
      {questionsOrder.length > 4 && <BackwardBtn />}
      <Box className={classes.indexContainer}>
        <motion.div
          className={classes.carousel}
          animate={{
            x: `${textDirection === "rtl" ? "+" : "-"}${
              (lastVisibleIndex - 3) * 25
            }%`
          }}
          transition={{ type: "spring", bounce: 0.25 }}>
          {questionsOrder.map((question, i) => (
            <ButtonBase
              key={i}
              variant="contained"
              size="large"
              sx={{
                borderRadius: "50%",
                minWidth: "42px",
                width: "42px",
                height: "42px",
                marginRight: "calc((25% - 42px) / 2)",
                color: "black",
                backgroundColor:
                  selectedQuestionId === question.id
                    ? "rgb(144, 202, 249)"
                    : "rgba(255, 255, 255, 0.5)",
                transition: "background-color 0.3s",
                "&:hover, &:focus": {
                  backgroundColor:
                    selectedQuestionId === question.id
                      ? "rgb(144, 202, 249)"
                      : "white"
                }
              }}
              onClick={() => {
                const newIndex =
                  question.displayIndex === undefined
                    ? i
                    : question.displayIndex;
                dispatch(setSelectedInteractionId(question.id));
                dispatch(
                  // TODO: refactor to use ID only and remove
                  setSelectedQuestionDisplayIndex(newIndex)
                );
                updateAnswerMode(newIndex);
                // }
              }}>
              <Typography className={classes.number}>{i + 1}</Typography>
            </ButtonBase>
          ))}
        </motion.div>
      </Box>
      {questionsOrder.length > 4 && <ForwardBtn />}
    </Box>
  );
}

QuestionsCarousel.propTypes = {
  questionsOrder: PropTypes.arrayOf(
    PropTypes.shape({
      displayIndex: PropTypes.number,
      questionIndex: PropTypes.number,
      mode: PropTypes.any
    })
  ),
  updateTaskFeedbackState: PropTypes.func
};

export default QuestionsCarousel;

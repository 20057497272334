// Dependencies
import React, { useEffect, useRef, useState } from "react";
import { httpCallables } from "../../../firebase";
import clsx from "clsx";
import { Link as RouterLink, useNavigate, useLocation } from "react-router";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { doc } from "firebase/firestore";
import { format } from "date-fns";

// Hooks
import { useDebounce } from "../../../hooks/useDebounce";
import { useQuery } from "../../../hooks/index";
import { useIntl } from "react-intl";

// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";
import { selectCourse } from "../../../redux/coursesSlice";
import {
  setSelectedText,
  selectTexts,
  selectText,
  setTextUrl
} from "../../../redux/textsSlice";
import {
  setTask,
  publishTask,
  STATUS,
  selectTaskSubmissions
} from "../../../redux/tasksSlice";
import {
  selectQuestions,
  setInteractions
} from "../../../redux/interactionsSlice";
import { addSnackbar } from "../../../redux/snackbarSlice";

// Components
import CreateAssignmentReadings from "./CreateAssignmentReadings";
import CreateAssignmentAssignment from "./CreateAssignmentAssignment";
import CreateAssignmentPublish from "./CreateAssignmentPublish";
import TaskStepper from "./TaskStepper";
import RouteLeavingGuard from "./RouteLeavingGuard";
import PangeaSpinner from "../../SharedComponents/PangeaSpinner";
import {
  TASK,
  USER_PROFILE,
  FEATURES,
  USER_TYPE,
  USER_ACTIONS
} from "../../../consts";
import { TooltipWithIntl } from "../../SharedComponents/index";

import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Typography
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { tasksAPI, userAPI } from "../../../api/index";
import useCourseId from "../../../hooks/useCourseId";

// Styles
const useStyles = makeStyles(() => ({
  header: {
    marginTop: "40px",
    position: "relative",
    display: "flex"
  },
  navigateBack: {
    left: "-60px",
    position: "absolute"
  },
  title: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  saveDraftlBtn: {
    position: "relative",
    alignSelf: "flex-end",
    "&.MuiButton-root.Mui-disabled": {
      color: "#989898"
    }
  },
  saveDraftlBtnLtr: {
    right: "0px"
  }
}));

function CreateAssignment({
  task,
  setTaskId,
  isDirty,
  setIsDirty,
  refreshTask,
  ...props
}) {
  //Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const intl = useIntl();
  const { pathname } = useLocation();
  const firestore = useFirestore();
  const saveQueue = useRef([]);
  const isSaveInProgress = useRef(false);
  const { task_id } = useQuery();
  let courseId = useCourseId();
  if (!courseId && task?.course_id) courseId = task.course_id;

  //Ephemeral State
  const [activeStep, setActiveStep] = useState(0);
  const [textId, setTextId] = useState(task.text_id || null);
  const [taskTitle, setTaskTitle] = useState("");

  const [taskType, setTaskType] = useState(
    task?.task_type || TASK.TYPE.STANDARD
  );

  const [taskDescription, setTaskDescription] = useState(
    task?.task_description || undefined
  );

  const [deadline, setDeadline] = useState(null);
  const [collaboratorDeadline, setCollaboratorDeadline] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [finalQuestions, setFinalQuestions] = useState([]);
  const [showSpinner] = useState(false); // When true a spinner will show to indicate that the task is saving
  const [botMode, setBotMode] = useState("full");
  const [lmsSyncDisabled, setLmsSyncDisabled] = useState(false);
  const [viewMode, setViewMode] = useState("edit");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [botConfig, setBotConfig] = useState({
    assistant: false,
    kind: ["Comprehension"],
    readingFocus: "",
    difficulty: 3
  });
  const [showDraftSaved, setShowDraftSaved] = useState(false);
  const [userChange, setUserChange] = useState(-1);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const [saveAndLeave, setSaveAndLeave] = useState(false);
  const [showGrBanner, setShowGrBanner] = useState(true);

  const course = useSelector((state) => selectCourse(state, Number(courseId)));
  const texts = useSelector((state) => selectTexts(state, Number(courseId)));
  const userId = useSelector((state) => state.firebase.auth.uid);
  const text = useSelector((state) => selectText(state, textId));
  const questionsFromDb = useSelector((state) =>
    selectQuestions(state, task?.id)
  );
  const status = useSelector((state) => state.tasks.status);
  const submissions = useSelector((state) =>
    selectTaskSubmissions(state, task?.id)
  );
  const taskRef = doc(
    firestore,
    `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${FEATURES.TASK}`
  );
  const { data: taskRefData } = useFirestoreDocData(taskRef);
  const taskCreationLmsSync = taskRefData?.taskCreationLmsSync || false;
  const taskCreationBot = taskRefData?.taskCreationBot || true;
  const taskCreationBotMode = taskRefData?.taskCreationBotMode || "full";

  // Derived State

  //Variables
  const TaskContext = React.createContext();
  const stepOne = !showSpinner && activeStep === 0;
  const stepTwo = !showSpinner && activeStep === 1;
  const stepThree = !showSpinner && activeStep === 2;
  const steps = [
    intl.formatMessage({
      id: "task.create.screen.readings",
      defaultMessage: "Readings"
    }),
    intl.formatMessage({
      id: "task.create.screen.assignment",
      defaultMessage: "Assignment"
    }),
    intl.formatMessage({
      id: "task.create.screen.publish",
      defaultMessage: "Publish"
    })
  ];

  //Behavior
  useEffect(() => {
    //load the textId
    const selectedText = texts.find((el) => el.id === textId);
    if (selectedText && selectedText.file_url) {
      httpCallables
        .textFunctions({
          func_name: "getTextDownloadUrl",
          courseId: course.id,
          fileUrl: selectedText.file_url,
          textId: selectedText.id
        })
        .then((response) => {
          if (response.data.success) {
            dispatch(setSelectedText(selectedText.id));
            dispatch(
              setTextUrl({
                url: response.data.payload,
                text_id: selectedText.id
              })
            );
          }
        });
    }
  }, [textId, course.id, dispatch]);

  useEffect(() => {
    const taskName = task?.name;
    if (taskName) setTaskTitle(taskName);
  }, [task?.name]);

  useEffect(() => {
    if (task?.id && task?.metadata?.bot) {
      setBotConfig(task?.metadata.bot);
    } else if (taskCreationBot !== undefined) {
      setBotConfig({ ...botConfig, ...{ assistant: taskCreationBot } });
    }
  }, [task, taskCreationBot]);

  useEffect(() => {
    if (task?.text_id) {
      setTextId(task?.text_id);
    }
  }, [task?.text_id]);

  useEffect(() => {
    if (task?.id && pathname.endsWith("edit")) {
      setViewMode("view");
    }
  }, [task?.id]);

  useEffect(() => {
    if (task?.original_due_date) setDeadline(new Date(task?.original_due_date));
  }, [task?.original_due_date]);

  useEffect(() => {
    if (task?.collaborator_due_date)
      setCollaboratorDeadline(new Date(task?.collaborator_due_date));
  }, [task?.collaborator_due_date]);

  useEffect(() => {
    let newBotMode = taskCreationBotMode || task?.bot_mode;
    if (newBotMode && botMode !== newBotMode) setBotMode(newBotMode);
  }, [taskCreationBotMode, task?.bot_mode]);

  useEffect(() => {
    if (task?.task_type) {
      setTaskType(task?.task_type);
    }
  }, [task?.task_type]);

  useEffect(() => {
    if (!questionsFromDb?.length) return;
    if (taskType === TASK.TYPE.GUIDED_READING) {
      setQuestions(
        questionsFromDb.map((question) => {
          const tempIdPart = question.id ? { tempId: question.id } : {};
          return {
            ...question,
            ...tempIdPart,
            question_context: TASK.TYPE.GUIDED_READING
          };
        })
      );
    } else {
      setQuestions(
        questionsFromDb.map((question) => {
          const tempIdPart = question.id ? { tempId: question.id } : {};
          return {
            ...question,
            ...tempIdPart,
            question_context: TASK.TYPE.STANDARD
          };
        })
      );
    }
  }, [taskType, questionsFromDb]);

  useEffect(() => {
    if (taskCreationLmsSync !== undefined)
      setLmsSyncDisabled(taskCreationLmsSync);
  }, [taskCreationLmsSync]);

  const setWrapper = (setFunc) => (state) => {
    setFunc(state);
    setIsDirty(true);
    setUserChange(new Date().getTime());
  };

  useEffect(() => {
    let parts = [];
    parts.push({
      url: "/tasks",
      resetCourse: true,
      text: intl.formatMessage({
        id: "appBar.tasks",
        resetTask: true,
        defaultMessage: "Assignments"
      })
    });
    course &&
      course.name &&
      parts.push({
        url: `/tasks?course_id=${course.id}`,
        text: course.name,
        resetTask: true,
        course: course
      });
    taskTitle &&
      parts.push({
        url: `/tasks?course_id=${course.id}`,
        resetTask: true,
        text: taskTitle
          ? taskTitle
          : intl.formatMessage({
              id: "new.task",
              defaultMessage: "New Task"
            })
      });
    dispatch(setBreadcrumbs({ breadcrumbs: parts, blue: true }));
  }, [taskTitle, course, dispatch, intl]);

  useEffect(() => {
    const finalQuestions = questions
      .filter((q) => !q.bot || q.bot.active)
      .map((q) => {
        // eslint-disable-next-line no-unused-vars
        const { bot, tempId, ...rest } = q;
        return rest;
      })
      .sort((a, b) => (a.order || 0) - (b.order || 0))
      .map((q, index) => ({ ...q, ...{ order: index } }));
    setFinalQuestions(finalQuestions);
  }, [questions]);

  const debouncedSaveDraft = useDebounce(saveDraft, 1000);
  useEffect(() => {
    // Early return conditions
    const isPublished = task?.status === "Submitted";
    const isEditingPublished = isPublished && viewMode === "edit";

    if (
      !textId || // No text selected
      isPublishing || // Currently publishing
      isSaveInProgress.current || // Save already in progress
      (isPublished && !isEditingPublished) || // Published and not in edit mode
      (!userChange && isPublished) // No changes on published task
    ) {
      return;
    }

    const saveDraftInternal = async () => {
      try {
        const data = await saveDraft();
        if (data?.payload) {
          const { task: draft } = JSON.parse(data.payload);
          if (draft) {
            setTaskId(draft.id);
          }
        }
      } catch (error) {
        console.error("Failed to save draft:", error);
        dispatch(
          addSnackbar({
            message: intl.formatMessage({
              id: "task.saveDraftError",
              defaultMessage: "Failed to save draft internal"
            })
          })
        );
      }
    };

    // Queue management for saves
    const queueSave = () => {
      if (saveQueue.current.length === 0 && !isSaveInProgress.current) {
        const savePromise = task?.id
          ? debouncedSaveDraft()
          : saveDraftInternal();
        saveQueue.current.push(savePromise);

        Promise.resolve(savePromise).finally(() => {
          saveQueue.current.shift();
          if (saveQueue.current.length > 0) {
            queueSave();
          }
        });
      }
    };

    queueSave();

    // Cleanup function
    return () => {
      if (typeof debouncedSaveDraft.cancel === "function") {
        debouncedSaveDraft.cancel();
      }
    };
  }, [
    userChange,
    textId,
    task?.id,
    task?.status,
    isPublishing,
    debouncedSaveDraft,
    viewMode
  ]);

  async function handlePublishTask() {
    userAPI.logAction({
      action_name: USER_ACTIONS.TASK_PUBLISH,
      payload: {
        task_id: task?.id,
        course_id: course.id,
        text_id: text.id,
        taskCreationBot: taskCreationBot
      }
    });

    setIsPublishing(true);
    dispatch(
      publishTask({
        id: task?.id,
        task_id: task?.id,
        isDraft: false,
        course_id: course.id,
        text_id: text.id,
        name: taskTitle,
        description: taskDescription,
        due_date: deadline?.toISOString(),
        collaborator_due_date: collaboratorDeadline?.toISOString(),
        task_type: taskType,
        questions: finalQuestions,
        bot_mode: botMode,
        lms_sync_disabled: lmsSyncDisabled,
        bot: botConfig
      })
    );
  }

  const saveTask = () => {
    setIsPublishing(true);
    const payload = {
      func_name: "updateTask",
      task_id: task?.id,
      questions: finalQuestions,
      name: taskTitle,
      description: taskDescription,
      text_id: text.id,
      dueDate: deadline?.toISOString(),
      acceptDate: deadline?.toISOString(),
      taskType: taskType,
      courseId: course.id,
      metadata: { bot: botConfig },
      bot_mode: botMode,
      lms_sync_disabled: lmsSyncDisabled
    };
    if (taskType === "peerReview") {
      payload.collaboratorDueDate = collaboratorDeadline?.toISOString();
    }
    httpCallables.taskFunctions(payload).then((response) => {
      if (response) {
        setIsDirty(false);

        navigate(`/tasks?course_id=${task?.course_id}`);
      } else {
        // TODO: implement error alert
      }
    });
  };

  async function saveDraft(taskId = task_id ? Number(task_id) : task?.id) {
    if (isPublishing || isSaveInProgress.current) return;

    try {
      isSaveInProgress.current = true;
      setIsSavingDraft(true);

      const { data } = await httpCallables.taskFunctions({
        func_name: "publishTask",
        isDraft: true,
        id: taskId,
        course_id: course.id,
        text_id: textId,
        name: taskTitle,
        description: taskDescription,
        due_date: deadline?.toISOString(),
        collaborator_due_date: collaboratorDeadline?.toISOString(),
        task_type: taskType,
        questions: finalQuestions,
        bot: botConfig,
        bot_mode: botMode,
        lms_sync_disabled: lmsSyncDisabled
      });

      if (data.success) {
        setIsDirty(false);
        setUserChange(0);

        const { interactions, task: draft } = JSON.parse(data.payload);

        if (interactions) {
          dispatch(setInteractions(interactions));
        }

        dispatch(setTask({ task: draft, submissions: [] }));
        setShowDraftSaved(true);
        setTimeout(() => setShowDraftSaved(false), 5000);

        if (!taskId) {
          setTaskId(draft.id);
          if (!saveAndLeave) {
            window.history.replaceState(
              {},
              "",
              `/tasks/new?course_id=${course.id}&task_id=${draft.id}`
            );
          }
        }
      }
      return data;
    } catch (error) {
      console.error("Failed to save draft:", error);
      dispatch(
        addSnackbar({
          message: intl.formatMessage({
            id: "task.saveDraftError",
            defaultMessage: "Failed to save draft"
          })
        })
      );
    } finally {
      setIsSavingDraft(false);
      isSaveInProgress.current = false;
    }
  }

  useEffect(() => {
    if (saveAndLeave && !isSavingDraft) {
      navigate(`/tasks?course_id=${course.id}`);
      dispatch(
        addSnackbar({
          message: intl.formatMessage({
            id: "task.savedDraft",
            defaultMessage: "Assignment saved as draft"
          })
        })
      );
    }
  }, [saveAndLeave, isSavingDraft, history]);

  const renderCancelDialog = () => {
    return (
      <Dialog
        open={showCancelDialog}
        onClose={() => {
          setShowCancelDialog(false);
        }}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm">
        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
          {intl.formatMessage({
            id: "task.create.screen.cancelDialogTitle",
            defaultMessage: "Discrad changes"
          })}
        </DialogTitle>
        <DialogContent className={clsx(classes.dialog, classes.ltr)}>
          <Typography>
            {intl.formatMessage({
              id: "task.create.screen.cancelDialogContent",
              defaultMessage: "Are you sure you want to discard changes?"
            })}
          </Typography>
        </DialogContent>
        <DialogActions className={classes.btns}>
          <Button
            className={clsx(classes.cancelBtn, classes.cancelBtnLtr)}
            onClick={() => setShowCancelDialog(false)}
            sx={{ color: "#787877" }}>
            {intl.formatMessage({
              id: "task.create.screen.cancelDialogKeepEditing",
              defaultMessage: "Keep editing"
            })}
          </Button>
          <Button
            className={clsx(classes.yesBtn, classes.yesBtnLtr)}
            onClick={() => {
              setShowCancelDialog(false);
              cancelEdit();
            }}
            color="primary">
            {intl.formatMessage({
              id: "task.create.screen.cancelDialogDiscard",
              defaultMessage: "Discard"
            })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const cancelEdit = () => {
    setViewMode("view");
    refreshTask();
  };

  const renderDeleteDialog = () => {
    return (
      <Dialog
        open={showDeleteDialog}
        onClose={() => {
          setShowDeleteDialog(false);
        }}
        aria-labelledby="delete-dialog-title"
        data-testid="delete-dialog">
        <DialogTitle id="delete-dialog-title" className={classes.dialogTitle}>
          {intl.formatMessage({
            id: "task.create.screen.deleteDialogTitle",
            defaultMessage: "Confirm Assignment Deletion"
          })}
        </DialogTitle>
        <DialogContent
          className={clsx(classes.dialog, classes.ltr)}
          data-testid="delete-dialog-content">
          <Typography>
            {intl.formatMessage({
              id: "task.create.screen.deleteDialogContent",
              defaultMessage:
                "Are you sure you want to delete this assignment? This action cannot be undone, and any work done by students will be permanently deleted. Students will be notified that the assignment has been deleted."
            })}
          </Typography>
        </DialogContent>
        <DialogActions className={classes.btns}>
          <Button
            className={clsx(classes.cancelBtn, classes.cancelBtnLtr)}
            onClick={() => setShowDeleteDialog(false)}
            aria-label="Cancel deletion"
            data-testid="cancel-delete-button">
            {intl.formatMessage({
              id: "general.cancel",
              defaultMessage: "Cancel"
            })}
          </Button>
          <Button
            className={clsx(classes.yesBtn, classes.yesBtnLtr)}
            onClick={() => {
              tasksAPI.deleteTask(task);
              setShowDeleteDialog(false);
              navigate(`/tasks?course_id=${course.id}`);
            }}
            color="primary"
            aria-label="Confirm Deletion"
            data-testid="confirm-delete-button">
            {intl.formatMessage({
              id: "general.confirmDelete",
              defaultMessage: "Confirm Deletion"
            })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  if (status === STATUS.PENDING) return <PangeaSpinner />;
  else
    return (
      <>
        <RouteLeavingGuard
          setIsDirty={setIsDirty}
          isDirty={isDirty}
          selectCourse={props.selectCourse}
          setTask={props.setTask}
          when={userChange > -1 && !task_id && !saveAndLeave}
          navigate={async (path) => {
            isSaveInProgress.current = true;
            await tasksAPI.deleteTask(task);
            isSaveInProgress.current = false;
            navigate(path);
          }}
          additionalStep={() => setSaveAndLeave(true)}
          additionalStepButton={intl.formatMessage({
            id: "tasks.saveDraft",
            defaultMessage: "Save draft"
          })}
        />
        <Box className={classes.header}>
          {task?.status === "Submitted" && viewMode === "edit" ? (
            <IconButton
              aria-label="go-back"
              className={classes.navigateBack}
              size="small"
              onClick={() =>
                isDirty ? setShowCancelDialog(true) : setViewMode("view")
              }>
              <ArrowBackIcon />
            </IconButton>
          ) : (
            <Link
              aria-label={"go back"}
              to={`/tasks?course_id=${course.id}`}
              component={RouterLink}>
              <IconButton
                aria-label="go-back"
                onClick={() => {}}
                size="small"
                className={classes.navigateBack}>
                <ArrowBackIcon />
              </IconButton>
            </Link>
          )}
          <Box className={classes.title}>
            <Typography variant="h5">
              {viewMode === "view"
                ? task.name
                : intl.formatMessage({
                    id: "task.create.screen.title",
                    defaultMessage: "Create a new reading assignment"
                  })}
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              {showDraftSaved && (
                <Box
                  sx={{ marginRight: "15px", fontSize: "14px", color: "grey" }}>
                  {intl.formatMessage({
                    id: "task.create.screen.draftSaved",
                    defaultMessage: "Draft saved at"
                  }) + format(new Date(), "p")}
                </Box>
              )}
              {viewMode === "view" ? (
                <Box className={clsx(classes.saveDraftlBtn)}>
                  <TooltipWithIntl
                    intlStringId={
                      submissions.filter(
                        (s) =>
                          s.status !== "Pending" && s.role === USER_TYPE.STUDENT
                      ).length > 0
                        ? "task.create.screen.editTaskDisabled"
                        : "task.create.screen.editTaskEnabled"
                    }
                    defaultMessage={"Edit task"}
                    placement={"top"}>
                    <IconButton
                      size={"medium"}
                      disabled={
                        submissions.filter(
                          (s) =>
                            s.status !== "Pending" &&
                            s.role === USER_TYPE.STUDENT
                        ).length > 0
                      }
                      onClick={() => setViewMode("edit")}
                      sx={{ maxHeight: "37px" }}
                      aria-label={"Edit assignment"}>
                      <EditOutlinedIcon />
                    </IconButton>
                  </TooltipWithIntl>
                  <TooltipWithIntl
                    intlStringId={"task.create.screen.deleteTask"}
                    defaultMessage={"Delete assignment"}
                    placement={"top"}>
                    <IconButton
                      size={"medium"}
                      onClick={() => {
                        setShowDeleteDialog(true);
                      }}
                      disabled={false}
                      sx={{ maxHeight: "37px" }}
                      aria-label={"Delete assignment"}>
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </TooltipWithIntl>
                </Box>
              ) : task?.id && task?.status !== "Draft" ? (
                <Button
                  variant="outlined"
                  className={clsx(
                    classes.saveDraftlBtn,
                    classes.saveDraftlBtnLtr
                  )}
                  onClick={() =>
                    isDirty ? setShowCancelDialog(true) : setViewMode("view")
                  }
                  sx={{ minHeight: "37px" }}>
                  {intl.formatMessage({
                    id: "general.cancel",
                    defaultMessage: "Cancel"
                  })}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  className={clsx(
                    classes.saveDraftlBtn,
                    classes.saveDraftlBtnLtr
                  )}
                  sx={{ minHeight: "37px" }}
                  disabled={userChange === -1}
                  onClick={() => setSaveAndLeave(true)}>
                  {intl.formatMessage({
                    id: "task.saveDraft",
                    defaultMessage: "Save & leave"
                  })}
                </Button>
              )}
            </Box>
          </Box>
          {showCancelDialog && renderCancelDialog()}
        </Box>
        <Box>
          <TaskContext.Provider value={task}>
            <TaskStepper
              rtl={false}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              steps={steps}
              viewOnly={viewMode === "view"}
            />
          </TaskContext.Provider>
          {showSpinner && <PangeaSpinner />}
          <Box sx={{ marginTop: "66px" }}>
            {stepOne && (
              <CreateAssignmentReadings
                course={course}
                texts={texts}
                text={text}
                setTextId={setWrapper(setTextId)}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                questions={questions}
                publishTask={handlePublishTask}
                saveTask={saveTask}
                setIsDirty={setIsDirty}
                resetQuestions={() => {}}
                taskType={taskType}
                setTaskType={setWrapper(setTaskType)}
                setQuestions={setWrapper(setQuestions)}
                botMode={botMode}
                setBotMode={setWrapper(setBotMode)}
                lmsSyncDisabled={lmsSyncDisabled}
                setLmsSyncDisabled={setWrapper(setLmsSyncDisabled)}
                readOnly={viewMode === "view"}
                isEdit={task?.status === "Submitted"}
              />
            )}
            {stepTwo && (
              <CreateAssignmentAssignment
                task={task}
                text={text}
                questions={questions}
                setQuestions={setWrapper(setQuestions)}
                taskTitle={taskTitle}
                setTaskTitle={setWrapper(setTaskTitle)}
                taskType={taskType}
                setTaskType={setWrapper(setTaskType)}
                taskDescription={taskDescription}
                setTaskDescription={setWrapper(setTaskDescription)}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                publishTask={handlePublishTask}
                saveTask={saveTask}
                readOnly={viewMode === "view"}
                botConfig={botConfig}
                setBotConfig={setWrapper(setBotConfig)}
                showGrBanner={showGrBanner}
                setShowGrBanner={setShowGrBanner}
              />
            )}
            {stepThree && (
              <CreateAssignmentPublish
                taskType={taskType}
                deadline={deadline}
                setDeadline={setWrapper(setDeadline)}
                collaboratorDeadline={collaboratorDeadline}
                setCollaboratorDeadline={setWrapper(setCollaboratorDeadline)}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                publishTask={handlePublishTask}
                saveTask={saveTask}
                setIsDirty={setIsDirty}
                readOnly={viewMode === "view"}
                isEdit={task?.status === "Submitted"}
              />
            )}
            {showDeleteDialog && renderDeleteDialog()}
          </Box>
        </Box>
      </>
    );
}
export default CreateAssignment;

import React, { useState } from "react";
import { useEffect } from "react";
import { doc } from "firebase/firestore";
import { useFirestore, useFirestoreDoc } from "reactfire";
import { Box, Button } from "@mui/material";
import Banner from "../../../SharedComponents/Banner";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { setIsOpen } from "../../../../redux/emailModalSlice.js";

const IntroEmailBanner = ({ introBannerShown, setBannerShown }) => {
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const selectedCourseId = useSelector(
    (state) => state.user.userProfile.selectedCourseId
  );
  const tasks = useSelector((state) => state.tasks.tasks);
  const defaultBannerText = `Help your students get started with Alethea’s introduction email`;
  const [bannerText, setBannerText] = useState(defaultBannerText);
  const introEmailRef = doc(firestore, "introEmail", `${selectedCourseId}`);
  const { status, data: snapshot } = useFirestoreDoc(introEmailRef);
  const timestampToDate = (timestamp) =>
    timestamp ? timestamp.toDate() : null;

  const hasEarliestDueDatePassed = (items) => {
    if (!items) return true;
    if (items.length === 0) return false;
    const now = new Date();
    return items.some(
      (item) => item.original_due_date && new Date(item.original_due_date) < now
    );
  };

  const getBannerText = (data) => {
    let text = defaultBannerText;
    if (data?.mailSent) {
      text = `Introduction email sent`;
    } else if (data?.scheduleTime) {
      text = `Introduction email scheduled for ${format(timestampToDate(data.scheduleTime), "EEE MMM d 'at' h a")}`;
    }
    return text;
  };
  useEffect(() => {
    if (status === "loading") return;
    setBannerText(getBannerText(snapshot?.data()));
    setBannerShown(
      !snapshot?.data()?.mailSent &&
        !hasEarliestDueDatePassed(tasks) &&
        !snapshot?.data()?.scheduleTime
    );
  }, [snapshot, status, tasks]);

  const renderBannerContent = () => {
    if (!introBannerShown) return null;
    return (
      <Box sx={{ width: "100%", position: "relative" }}>
        <Box
          sx={{
            padding: "16px",
            backgroundColor: "background.default",
            position: "absolute",
            width: "100%",
            zIndex: "1000"
          }}>
          <Banner
            body={bannerText}
            actions={
              <>
                <Button size="medium" onClick={() => dispatch(setIsOpen(true))}>
                  Edit email
                </Button>
              </>
            }
            showCloseIcon={false}
          />
        </Box>
      </Box>
    );
  };

  return renderBannerContent();
};

export default IntroEmailBanner;

// Dependencies
import React, { useEffect } from "react";
import { useQuery } from "../../../hooks";
import { motion } from "framer-motion";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { selectSubmission } from "../../../redux/tasksSlice";
import clsx from "clsx";
import { selectCourse } from "../../../redux/coursesSlice";

// Components
import TaskFeedback from "../TaskFeedback/TaskFeedback";
import ReaderContent from "../../reader/ReaderContent";
import StandardSidebar from "../../Sidebar/StandardSidebar";
import { SecondarySidebarLayout } from "../../SharedComponents";
import Sidebar from "../../../Sidebar";

// Material UI
import CommentPanel from "../../comments/CommentPanel";

import {
  selectIsComments,
  selectSecondarySidebarCollapsed,
  selectIsThumbnails,
  selectIsPrimarySidebar
} from "../../../redux/firestoreSelectors";

import PDFThumbBar from "../../reader/pdf/sidebar/PDFThumbBar";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import { togglePrimarySidebar } from "../../../redux/layoutSlice";
import { isPdfSelector } from "../../../redux/textsSlice";
import { USER_TYPE } from "../../../consts";
import TasksButton from "../TasksButton";

const useStyles = makeStyles((theme) => {
  return {
    readerViewContainer: {
      position: "relative",
      flex: 1,
      height: "100%",
      justifyContent: "center"
    },
    innerWrapper: {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0
    }
  };
});

function StandardTask() {
  // Hooks
  const { submission_id, type = null } = useQuery();
  const classes = useStyles();
  const dispatch = useDispatch();

  // Redux Selectors
  const isThumbnails = useSelector((state) => selectIsThumbnails(state));
  const isComments = useSelector((state) => selectIsComments(state));
  const taskStep = useSelector((state) => state.task.step);
  const isSecondarySidebar = useSelector((state) =>
    selectSecondarySidebarCollapsed(state)
  );
  const isPrimarySidebar = useSelector((state) =>
    selectIsPrimarySidebar(state)
  );
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const isPdf = useSelector(isPdfSelector);

  const course = useSelector((state) =>
    selectCourse(state, submission.course_id)
  );
  const uid = useSelector((state) => state.user.auth.uid);
  const PrimarySidebarWidth = useSelector(
    (state) => state.layout.primarySidebarWidth
  );
  const SecondarySidebarWidth = useSelector(
    (state) => state.layout.secondarySidebarWidth
  );

  // Ephemeral state

  //user role
  const isTeacher =
    course.course_role === "Teacher" &&
    type?.toUpperCase() !== USER_TYPE.STUDENT;
  const isCourseAdmin =
    course.course_role === "Admin" && type?.toUpperCase() !== USER_TYPE.STUDENT;

  // yes, we can use !isTeacher, but it is less readable
  const isStudent =
    course.course_role === "Student" ||
    type?.toUpperCase() === USER_TYPE.STUDENT;
  const isStepHighlight = taskStep === "highlight";
  //submission status
  const status = submission.status;
  const isGraded = status === "Graded";
  const isSubmitted = status === "Submitted";

  //Behavior
  useEffect(() => {
    if (!isPrimarySidebar) dispatch(togglePrimarySidebar());
  }, []);

  // Render
  if (isStudent && !isGraded) {
    return (
      <>
        <TasksButton />
        <Sidebar>
          <StandardSidebar />
        </Sidebar>
        <Box
          className={clsx(classes.readerViewContainer, classes.dirLtr)}
          style={{
            width:
              isStepHighlight && isSecondarySidebar
                ? `calc(100vw - ${PrimarySidebarWidth}px - ${SecondarySidebarWidth}px)`
                : `calc(100vw - ${PrimarySidebarWidth}px`
          }}>
          <motion.div
            className={classes.innerWrapper}
            animate={{
              zIndex: 0,
              opacity: 1
            }}
            style={{
              pointerEvents: "all"
            }}
            transition={{ duration: 0.5 }}>
            <ReaderContent />
          </motion.div>
        </Box>
        {isStepHighlight ? ( // only show the secoundary sidebar when the reader is on view
          <SecondarySidebarLayout collapsed={!isSecondarySidebar}>
            {isThumbnails && <PDFThumbBar />}
            {isComments && <CommentPanel />}
          </SecondarySidebarLayout>
        ) : (
          <></>
        )}
      </>
    );
  }
  if ((isTeacher || isCourseAdmin) && isSubmitted) return <TaskFeedback />;
  if (isGraded) return <TaskFeedback />;
}

StandardTask.propTypes = {};

export default StandardTask;

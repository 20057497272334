// Dependencies
import React, { useEffect, useState } from "react";
import { httpCallables } from "../../../firebase";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { captureException } from "../../../utils/errorHandlers";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  addSnackbar,
  removeSnackbarUndoActions
} from "../../../redux/snackbarSlice.js";
import {
  setSubmittedStudentTask,
  setSubmittedStudentReflection,
  setSubmittedStudentReview,
  setSentFeedbackTask
} from "../../../redux/taskSlice";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";
import { selectSelectedCourse } from "../../../redux/coursesSlice";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

// Components
import TasksTeacherView from "../../Tasks/TaskManager/TasksTeacherView";
import TasksStudentView from "../../Tasks/TaskManager/TasksStudentView";
import ScrollBox from "../../SharedComponents/ScrollBox";
import ExportBanner from "./courseMaterialExport/ExportBanner";
import { COURSE_MATERIAL_TYPE } from "./courseMaterialExport/consts";
import IntroEmailBanner from "./introductionEmail/IntroEmailBanner.jsx";
import { useFeatureFlags } from "../../../hooks/useFeatureFlags.js";
import { FEATURE_FLAGS } from "../../../consts.js";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      flex: 1,
      minHeight: "70%",
      // width: "83.333%", // 10 culumns on a 12 column grid
      paddingInline: 40,
      alignItems: "center",
      position: "relative",
      // margin: "0 auto",

      [theme.breakpoints.up("desktop")]: {
        // maxWidth: 900,
        marginInline: theme.spacing(8)
      }
    }
  };
});

export default function TaskManager() {
  // Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const [refreshToken, setRefreshToken] = React.useState(0);
  const isIntroEmailEnabled = useFeatureFlags(FEATURE_FLAGS.INTRODUCTION_EMAIL);

  const intl = useIntl();
  //Redux

  const course = useSelector(selectSelectedCourse);

  const sentFeedbackTask = useSelector((state) => state.task.sentFeedbackTask);
  const sentFeedbackTextTask = useSelector(
    (state) => state.task.sentFeedbackTextTask
  );
  const undoActions = useSelector((state) => state.snackbars.undoActions);
  const submittedStudentTask = useSelector(
    (state) => state.task.submittedStudentTask
  );
  const submittedStudentReflection = useSelector(
    (state) => state.task.submittedStudentReflection
  );
  const submittedStudentResponse = useSelector(
    (state) => state.task.submittedStudentResponse
  );
  const submittedStudentReply = useSelector(
    (state) => state.task.submittedStudentReply
  );

  const submissions = useSelector((state) => state.tasks.submissions);

  const [exportBannerShown, setExportBannerShown] = useState(false);
  const [introBannerShown, setIntroBannerShown] = useState(false);

  // TODO: need to refactor the undos to work on submission ID and not task_id
  // once this is done, remove getTaskSubmission
  function getTaskSubmission(task_id) {
    submissions.find((submission) => submission.task_id === task_id);
  }
  //Behavior

  // Invoke the undo logic when the Redux undo flag is true
  useEffect(() => {
    const undoStudentTaskSubmission = () => {
      const taskId = submittedStudentTask;

      httpCallables
        .undoStudentTaskSubmissions({
          task_id: taskId
        })
        .then(() => {
          dispatch(setSubmittedStudentTask(0));
          setRefreshToken(refreshToken + 1);
          navigate(`/task?task_id=${taskId}`);
        });
    };

    const undoSubmitPeerReviewReflection = () => {
      const taskId = submittedStudentReflection;
      const submission_id = getTaskSubmission(taskId);

      httpCallables
        .undoSubmitPeerReviewReflection({
          submission_id: submission_id
        })
        .then(() => {
          dispatch(setSubmittedStudentReflection(0));
          setRefreshToken(refreshToken + 1);
          navigate(`/task?task_id=${taskId}`);
        });
    };

    const undoSubmitPeerReviewResponse = () => {
      const taskId = submittedStudentResponse;

      httpCallables
        .undoSubmitPeerReviewResponse({
          task_id: taskId
        })
        .then((response) => {
          dispatch(setSubmittedStudentReview(0));
          setRefreshToken(refreshToken + 1);
          navigate(`/task?submission_id=${response.data}`);
        });
    };

    const undoSubmitPeerReviewReply = () => {
      const submission_id = submittedStudentReply;

      httpCallables
        .undoSubmitPeerReviewReply({
          submission_id: submission_id
        })
        .then((response) => {
          dispatch(setSubmittedStudentReview(0));
          setRefreshToken(refreshToken + 1);
          navigate(`/task?submission_id=${response.data}`);
        });
    };

    const undoTaskFeedbackSubmission = () => {
      const taskId = sentFeedbackTask;

      httpCallables
        .undoTaskFeedbackSubmission({ task_id: taskId })
        .then(() => {
          dispatch(setSentFeedbackTask(0));
          setRefreshToken(refreshToken + 1);
        })
        .catch((err) => captureException(err));
    };

    const shouldUndo = undoActions.find(
      (u) => u.component === "TaskManager" && u.id === course.id
    );
    if (shouldUndo) {
      if (submittedStudentTask > 0) {
        undoStudentTaskSubmission();
      } else if (submittedStudentReflection > 0) {
        undoSubmitPeerReviewReflection();
      } else if (submittedStudentResponse > 0) {
        undoSubmitPeerReviewResponse();
      } else if (submittedStudentReply > 0) {
        undoSubmitPeerReviewReply();
      } else if (sentFeedbackTask > 0) {
        undoTaskFeedbackSubmission();
      }
      dispatch(
        removeSnackbarUndoActions({ component: "TaskManager", id: course.id })
      );
    }
  }, [undoActions]);

  // // Show flash message when there is undo data

  useEffect(() => {
    if (submittedStudentTask > 0) {
      dispatch(
        addSnackbar({
          message: intl.formatMessage({
            id: "task.submission_msg",
            defaultMessage: "Task submitted"
          }),
          undoButton: true,
          undoData: { component: "TaskManager", id: course.id }
        })
      );
    }
  }, [dispatch, submittedStudentTask]);

  useEffect(() => {
    if (sentFeedbackTask) {
      dispatch(
        addSnackbar({
          message: intl.formatMessage({
            id: "task.feedback_sent",
            defaultMessage: "Feedback sent"
          }),
          undoButton: true,
          undoData: { component: "TaskManager", id: course.id }
        })
      );
    }
  }, [dispatch, sentFeedbackTask]);

  useEffect(() => {
    if (sentFeedbackTextTask > 0) {
      dispatch(
        addSnackbar({
          message: intl.formatMessage({
            id: "task.feedback_sent",
            defaultMessage: "Feedback sent"
          }),
          undoButton: true,
          undoData: { component: "TaskManager", id: course.id }
        })
      );
    }
  }, [dispatch, sentFeedbackTextTask]);

  //for breadcrumbs
  useEffect(() => {
    let parts = [];
    parts.push({
      text: intl.formatMessage({
        id: "appBar.tasks",
        defaultMessage: "Assignments"
      }),
      url: "/tasks",
      resetCourse: true
    });
    course.id &&
      parts.push({
        url: `/tasks?course_id=${course.id}`,
        text: course.name,
        course: course
      });

    dispatch(
      setBreadcrumbs({ breadcrumbs: parts, blue: true, showTextMenu: false })
    );
  }, [course.id, course.name]);

  // TODO: implament empty state
  if (!course?.id) return null;
  else
    return (
      <ScrollBox className={classes.scrollBox}>
        {course.course_role === "Teacher" && (
          <Box sx={{ gap: "60px", display: "flex", flexDirection: "column" }}>
            <ExportBanner
              bannerType={COURSE_MATERIAL_TYPE.TASK}
              setBannerShown={setExportBannerShown}
            />
            {isIntroEmailEnabled && (
              <IntroEmailBanner
                introBannerShown={introBannerShown}
                setBannerShown={setIntroBannerShown}
              />
            )}
          </Box>
        )}
        <Box
          className={classes.container}
          sx={{
            marginTop:
              exportBannerShown || (introBannerShown && isIntroEmailEnabled)
                ? "90px !important"
                : "0"
          }}
          data-testid="task-manager">
          {["Teacher", "Admin"].includes(course.course_role) && (
            <TasksTeacherView course={course} refresh={refreshToken} />
          )}

          {course.course_role === "Student" && (
            <TasksStudentView
              course={course}
              refreshToken={refreshToken}
              refresh={refreshToken}
            />
          )}
        </Box>
      </ScrollBox>
    );
}

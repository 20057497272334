const CreateInitialData = (courseName) => {
  return {
    subject: "Hello and Welcome to Alethea",
    content:
      `You've been invited to Alethea by your instructor, in the course ${courseName}.\n` +
      "\n" +
      "Alethea is a tool that blends proven learning principles with generative AI to enhance engagement with your reading materials and nurture healthy learning skills.\n" +
      "\n" +
      "How to log in with your LMS (Canvas or Moodle):\n" +
      "1. Login to your LMS and go to the relevant course.\n" +
      "2. In the course, go to “Modules” (Canvas) or the course sections (Moodle) and look for the link to “Alethea”.\n" +
      "3. Click on the link, and Alethea will open in a new tab - you’re in!\n" +
      "\n" +
      "For more information and tutorials, we recommend visiting our help center: https://alethea.zendesk.com/hc/en-us.\n" +
      "\n" +
      "If you have any questions or encounter any issues, feel free to contact us at support.alethea@clarivate.com.\n",
    scheduleTime: null,
    mailSent: false
  };
};

export default CreateInitialData;

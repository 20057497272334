import FlexSearch from "flexsearch";
import ePub from "epubjs";
import { stemmer } from "stemmer";
import { INTERACTION_SUBTYPES } from "../../../consts";

const EpubCFI = new ePub.CFI();

export function mapConcepts(question, answer) {
  if (!question || !question.concepts || !answer || !answer.content) return [];
  // Naive solution: We are assuming that if there are two words in hebrew, than the whole content should be matched in RTL
  const regex = /[יכלמנסעפצקרשתאבגדהוזחט]+\s+[אבגדהוזחטיכלמנסעפצקרשת]+/g;
  const isRtl = answer.content.search(regex) >= 0;

  let flexIndex = new FlexSearch.Index({
    /**
     * @type {Object<string, string>}
     * @export
     */

    stemmer: function (value) {
      // apply some replacements
      // ...

      return stemmer(value);
    },

    tokenize: "forward",
    rtl: isRtl,
    split: isRtl ? /\s+/ : /\W+/
  });

  flexIndex.add(answer.id, answer.content);

  const results = question.concepts.map((concept) => {
    const results = flexIndex.search(stemmer(concept));
    const exactResults = flexIndex.search(concept);
    return {
      concept: concept,
      found: results.includes(answer.id) || exactResults.includes(answer.id)
    };
  });

  return results;
}

export function calculateFindInTextScore(question, highlights) {
  let tempScore = 0;

  if (!highlights || !highlights.length) return 0;
  if (!question?.quotes || !question?.quotes.length) return 0;
  let teacherSortedQuotes = [...question.quotes].sort(function (a, b) {
    return EpubCFI.compare(a.cfi, b.cfi);
  });

  let studentSorted = [...highlights].sort(function (a, b) {
    return EpubCFI.compare(a.cfi, b.cfi);
  });

  let studentInd = 0;
  let teacherInd = 0;

  while (
    studentInd < studentSorted.length &&
    teacherInd < teacherSortedQuotes.length
  ) {
    let cfiParts = studentSorted[studentInd].cfi.split(","); // cfiBase:  cfiParts[0]
    let studentStartCfi = cfiParts[0] + cfiParts[1] + ")"; // start: 'epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)'
    let studentEndCfi = cfiParts[0] + cfiParts[2];

    cfiParts = teacherSortedQuotes[teacherInd].cfi.split(","); // cfiBase:  cfiParts[0]
    let teacherStartCfi = cfiParts[0] + cfiParts[1] + ")"; // start: 'epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)'
    let teacherEndCfi = cfiParts[0] + cfiParts[2];
    if (EpubCFI.compare(studentEndCfi, teacherStartCfi) <= 0) {
      studentInd++;
    } else if (EpubCFI.compare(teacherEndCfi, studentStartCfi) <= 0) {
      teacherInd++;
    } else {
      let needle = studentSorted[studentInd].content;
      let haystack = teacherSortedQuotes[teacherInd].content;
      if (haystack.length < needle.length) {
        haystack = needle;
        needle = teacherSortedQuotes[teacherInd].content;
      }
      let retVal = subCompare(needle, haystack);
      if (retVal.found) {
        tempScore += retVal.substring.length;
      }

      if (EpubCFI.compare(studentEndCfi, teacherEndCfi) >= 0) {
        studentInd++;
      } else {
        teacherInd++;
      }
    }
  }
  let totalTeacher = teacherSortedQuotes.reduce(
    (acc, curr) => acc + curr.content.length,
    0
  );

  return (1.0 * tempScore) / totalTeacher;
}

function subCompare(needle, haystack, min_substring_length = 1) {
  // Search possible substrings from largest to smallest:
  for (var i = needle.length; i >= min_substring_length; i--) {
    for (var j = 0; j <= needle.length - i; j++) {
      var substring = needle.substr(j, i);
      var k = haystack.indexOf(substring);
      if (k !== -1) {
        return {
          found: 1,
          substring: substring,
          needleIndex: j,
          haystackIndex: k
        };
      }
    }
  }
  return {
    found: 0
  };
}

const getMatchScore = (quesion, match) => {
  if (match === 0) return 0;
  let factored = match;
  if (match > 0.7) {
    factored = 1;
  } else if (match > 0.6) {
    factored = factored * 1.25;
  } else {
    factored = Math.min(0.6, factored * 1.55);
  }
  return Math.ceil(factored * parseFloat(quesion.points));
};

export const calculateQuestionPoints = (question, answer, highlights) => {
  // Early returns with explicit 0
  if (!question || !answer || !highlights) return 0;
  if (!question.points) return 0;

  const points = Math.round(Number(question.points));
  if (Number.isNaN(points) || points === 0) return 0;

  // Initialize score to 0 by default
  let calc = 0;

  // Only proceed with score calculation if there's valid input data
  const highlightMatch = calculateFindInTextScore(question, highlights);

  if (question.interaction_subtype === INTERACTION_SUBTYPES.FIND_IN_TEXT) {
    // For find in text, only calculate score if there are highlights
    calc = highlights.length ? getMatchScore(question, highlightMatch) : 0;
  } else if (question.interaction_subtype === INTERACTION_SUBTYPES.OPEN_ENDED) {
    const conceptMapping = mapConcepts(question, answer);
    let questionPart = 0; // Initialize to 0 instead of undefined

    // Only calculate concept score if there are concepts defined and found
    if (conceptMapping.length > 0) {
      const foundConcepts = conceptMapping.filter(
        (concept) => concept.found
      ).length;
      questionPart =
        foundConcepts > 0
          ? Math.ceil((foundConcepts / conceptMapping.length) * points)
          : 0;
    }

    // Only include citation score if specifically required and highlights exist
    if (question.includeCitation && highlights.length) {
      let quotePart = getMatchScore(question, highlightMatch);
      calc = Math.round((0.5 * quotePart + 0.5 * questionPart) * 100) / 100;
    } else {
      calc = questionPart;
    }
  } else if (
    question.interaction_subtype === INTERACTION_SUBTYPES.MULTI_CHOICE
  ) {
    // For multiple choice, only calculate score if there's a valid choice
    let questionPart = 0;
    if (answer.choice !== undefined && question.shouldSelect !== undefined) {
      questionPart =
        Number(question.shouldSelect) === Number(answer.choice) ? points : 0;
    }

    if (question.includeCitation && highlights.length) {
      let quotePart = getMatchScore(question, highlightMatch);
      calc = Math.round((0.5 * quotePart + 0.5 * questionPart) * 100) / 100;
    } else {
      calc = questionPart;
    }
  }

  // Ensure final score is valid and rounded
  return Math.min(Math.max(0, Math.round(calc)), points);
};

export const getWordCount = (content) => {
  const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
  const cleanString = content.replace(regex, " ").trim(); // replace above characters w/ space
  const wordArray = cleanString.match(/\S+/g); // matches words according to whitespace
  return wordArray && wordArray.length;
};

export const generateRandomKey = (length = 5) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

// Dependencies
import React, { useEffect, useState } from "react";
import { httpCallables } from "../../../firebase";
import { useNavigate } from "react-router";
import { useQuery } from "../../../hooks";

import { FormattedMessage } from "react-intl";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { captureException } from "../../../utils/errorHandlers";
import { useFeatureFlags } from "../../../hooks/useFeatureFlags";
// Redux Dependencies
import { useSelector, useDispatch } from "react-redux";
import { selectCourse } from "../../../redux/coursesSlice";
import { setIsOpen } from "../../../redux/emailModalSlice.js";
import {
  selectTasks,
  reorderTasks,
  selectCourseSubmissionsSortedByUserName,
  fetchTeacherCourseTasks,
  STATUS
} from "../../../redux/tasksSlice";
//Components
import TasksListItem from "./TasksListItem";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Button,
  Typography,
  List,
  Divider,
  IconButton,
  Menu,
  MenuItem
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PangeaSpinner from "../../SharedComponents/PangeaSpinner";
import ExportModal from "./courseMaterialExport/ExportModal";
import { COURSE_MATERIAL_TYPE } from "./courseMaterialExport/consts";
import { USER_TYPE, FEATURE_FLAGS, USER_ACTIONS } from "../../../consts";
import { userAPI } from "../../../api";
import IntroductionEmailModal from "./introductionEmail/IntroductionEmailModal.jsx";
import { setPersistent } from "../../../redux/userSlice.js";

const useStyles = makeStyles((theme) => ({
  wideCell: {
    width: "80%"
  },
  left: {
    textAlign: "left"
  },
  tableRow: {
    height: "52px"
  },
  studenBox: {
    marginLeft: "56px"
  },
  tableContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "48px",
    marginBottom: "24px"
  },
  taskContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBlockStart: theme.spacing(6),
    marginBlockEnd: theme.spacing(3)
  },
  tasksHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: theme.spacing(7.5),
    marginBottom: theme.spacing(5)
  },
  tasks: {
    backgroundColor: "transparent",
    boxShadow: "none",
    border: "none",
    "& .MuiAccordionSummary-content": {
      alignItems: "center",
      justifyContent: "space-between",
      order: 2
    }
  }
}));

export default function TasksTeacherView() {
  //Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { task_id } = useQuery();
  const selectedCourseId = useSelector(
    (state) => state.user.userProfile?.selectedCourseId
  );
  const isAnalyticsEnabled = useFeatureFlags(FEATURE_FLAGS.TEACHER_ANALYTICS);
  const isIntroEmailEnabled = useFeatureFlags(FEATURE_FLAGS.INTRODUCTION_EMAIL);

  const userId = useSelector((state) => state.firebase.auth.uid);

  // Redux State
  const course = useSelector((state) => selectCourse(state, selectedCourseId));
  const tasks = useSelector((state) =>
    selectTasks(state, selectedCourseId?.toString()).filter(
      (t) => t.status !== STATUS.PENDING
    )
  );
  const submissions = useSelector((state) =>
    selectCourseSubmissionsSortedByUserName(state, selectedCourseId)
  );
  const status = useSelector((state) => state.tasks.status);
  const courseTexts = useSelector((state) => state.texts.texts);

  // Ephemeral State
  const [exportModal, setExportModal] = useState(false);
  const [courseAndTeachers, setCourseAndTeachers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleExportClick = () => {
    setExportModal(true);
    handleMenuClose();
  };
  const handleIntroEmailClick = () => {
    dispatch(setIsOpen(true));
    handleMenuClose();
  };

  //TODO: this is duplicated code from Library
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!result.destination) return;
    // dropped in the same place
    if (destination.index === source.index) return;
    const items = reorder(tasks, source.index, destination.index);
    //call function for reorder:

    httpCallables
      .taskFunctions({
        func_name: "reorderTasks",
        course: course.id,
        tasks: items
      })
      .catch((err) => {
        captureException(
          err,
          `Failed to update teacher's tasks order. courseId ${course.id}`
        );
      });
    dispatch(reorderTasks(items));
  }
  useEffect(() => {
    dispatch(fetchTeacherCourseTasks({ course_id: selectedCourseId }));
  }, [dispatch, selectedCourseId]);

  useEffect(() => {
    const courseId = selectedCourseId;
    httpCallables
      .coursesFunctions({
        func_name: "getCourseInstitutionId",
        course_id: courseId
      })
      .then((response) => {
        const { institution_id } = response.data;
        httpCallables
          .coursesFunctions({
            func_name: "getAllCoursesWithTeachersByInstitutionId",
            institution_id: institution_id
          })
          .then((response) => {
            const { data } = response;
            const filteredCourses = data.filter(
              (course) => course.id !== selectedCourseId
            );
            setCourseAndTeachers(filteredCourses);
          });
      });
  }, [selectedCourseId]);

  const renderAnalyticsButton = () => {
    if (isAnalyticsEnabled) {
      return (
        <Button
          variant="outlined"
          color="primary"
          size="medium"
          sx={{
            marginInlineEnd: "8px"
          }}
          onClick={() => {
            dispatch(
              setPersistent({
                courseAnalyticsLastLocation: {
                  returnToLastLocation: false
                }
              })
            );
            navigate(`/reports/courseExternal?course_id=${course.id}`);
          }}>
          <FormattedMessage
            id="course.reports.button"
            defaultMessage="Analytics"
          />
        </Button>
      );
    } else return null;
  };

  if (status === STATUS.PENDING) {
    return <PangeaSpinner />;
  } else if (task_id) {
    const task = tasks.filter((task) => task.id == task_id)[0];
    const relevantSubmissions = submissions
      .filter(
        (submission) =>
          submission.task_id == task_id &&
          (submission.role?.toUpperCase?.() === USER_TYPE.STUDENT ||
            submission.owner === userId)
      )
      .sort((a, _b) => {
        // Show teacher on top
        if (a.role?.toUpperCase?.() === USER_TYPE.TEACHER) return -1;
        else return 1;
      });
    return (
      <Box className={classes.taskContainer} key={task_id}>
        {task ? (
          <TasksListItem
            course={course}
            openTasks={task_id}
            toggleTask={false}
            task={task}
            submissions={relevantSubmissions}
            singleItemView={true}
          />
        ) : (
          <PangeaSpinner />
        )}
      </Box>
    );
  } else {
    return (
      <>
        <Box className={classes.tasksHeader}>
          <Box sx={{ display: "flex", flexFlow: "row nowrap" }}>
            {/* Main heading for the page */}
            <Typography
              variant="h1"
              component="h1"
              sx={{
                marginInlineEnd: "16px",
                fontSize: "1.5rem", // Maintain visual size while using semantic h1
                fontWeight: "500"
              }}>
              {course.name}
            </Typography>
          </Box>
          <Box sx={{ minWidth: "fit-content" }}>
            {renderAnalyticsButton()}
            <Button
              variant="contained"
              color="primary"
              size="medium"
              data-testid="create-assignment"
              aria-label="Create new assignment for course"
              onClick={() => {
                navigate(`/tasks/new?course_id=${course.id}`);
                userAPI.logAction({
                  action_name: USER_ACTIONS.TASK_CREATION_SESSION,
                  payload: { course_id: course.id }
                });
              }}>
              <FormattedMessage id="tasks.create" defaultMessage="Create" />
            </Button>
            <IconButton
              color="primary"
              size="medium"
              onClick={handleMenuClick}
              aria-label={`${open ? "Close" : "Open"} course options menu`}
              aria-controls={open ? "course-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true">
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="course-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}>
              <MenuItem onClick={handleExportClick}>
                <FormattedMessage
                  id="tasks.share"
                  defaultMessage="Share assignments"
                />
              </MenuItem>
              {isIntroEmailEnabled && (
                <MenuItem onClick={handleIntroEmailClick}>
                  <FormattedMessage
                    id="tasks.introEmail"
                    defaultMessage="Introduction email"
                  />
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Box>

        <Divider />
        {!tasks.length ? (
          <Typography
            variant="h2"
            component="h2"
            sx={{
              fontSize: "1.25rem", // Maintain visual size while using semantic h2
              fontWeight: "500"
            }}>
            There are currently no tasks for this course
          </Typography>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={"1"} direction="vertical">
              {(provided) => {
                return (
                  <>
                    <List
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={classes.tasks}
                      aria-label="Course tasks list">
                      {tasks.map((task, index) => {
                        return (
                          <TasksListItem
                            key={task.id}
                            course={course}
                            task={task}
                            taskIndex={index}
                            graders={false}
                            submissions={submissions
                              .filter(
                                (submission) =>
                                  submission.task_id === task.id &&
                                  submission.related_submission_id === null &&
                                  (submission.role?.toUpperCase?.() ===
                                    USER_TYPE.STUDENT ||
                                    submission.owner === userId)
                              )
                              .sort((a, _b) => {
                                // Show teacher on top
                                if (
                                  a.role?.toUpperCase?.() === USER_TYPE.TEACHER
                                )
                                  return -1;
                                else return 1;
                              })}
                            singleItemView={false}
                            onDragEnd={onDragEnd}
                            tasksLength={tasks.length}
                          />
                        );
                      })}
                      {provided.placeholder}
                    </List>
                  </>
                );
              }}
            </Droppable>
          </DragDropContext>
        )}
        <ExportModal
          exportModal={exportModal}
          setExportModal={setExportModal}
          tasks={tasks}
          courseTexts={courseTexts}
          type={COURSE_MATERIAL_TYPE.TASK}
          courseAndTeachers={courseAndTeachers}
        />
        {isIntroEmailEnabled && <IntroductionEmailModal />}
      </>
    );
  }
}

export const navigateToTaskHandler = async (task, submission, navigate) => {
  // dispatch(setAction("task"));
  const isCollaboration = submission.related_submission_id !== null;
  const submissionUrl = `/task?submission_id=${submission.id}`;
  const collaborationUrl = `/task?submission_id=${submission.related_submission_id}`;
  // Navigate to peerReview collaboration
  if (
    (isCollaboration && !submission.submission_date) ||
    (isCollaboration &&
      submission.status === "Submitted" &&
      !submission.is_checked)
  ) {
    navigate(collaborationUrl);
  } else if (isCollaboration && submission.is_checked) {
    navigate(collaborationUrl);
  } else if (submission.is_checked) {
    navigate(submissionUrl);
    // Navigate to reader for GR task
  } else if (
    task.task_type === "peerReview" &&
    !submission.is_checked &&
    submission.status === "Submitted"
  ) {
    navigate(submissionUrl);

    // Navigate to teacher's feedback
  } else if (task.task_type === "guidedReading") {
    navigate(submissionUrl);
    // Direct to task
  } else {
    navigate(submissionUrl);
  }
};

import React, { useMemo, useState } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";

import { setClickedMatch } from "../../../redux/taskSlice";
import { selectCurrentText } from "../../../redux/textsSlice";
import CitationDialog from "../CreateTask/CitationDialog";
import {
  Divider,
  Box,
  Card,
  List,
  ListItem,
  Typography,
  Chip,
  Button
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { INTERACTION_SUBTYPES } from "../../../consts";
import { showInText } from "../../../utils/showInTextHelper";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    padding: "16px",
    textTransform: "none"
  },
  divider: {
    backgroundColor: "#616161",
    marginBottom: "16px",
    marginTop: "16px"
  },

  panelHeader: {
    color: "white",
    width: "100%",
    textTransform: "uppercase",
    marginTop: "16px"
  },

  questionLtr: {
    paddingRight: "24px",
    paddingLeft: "24px"
  },
  panelHeaderLtr: {
    textAlign: "left"
  },
  quoteRef: {
    marginTop: "16px",
    padding: "0px",
    fontSize: "12px",
    color: theme.palette.primary,
    cursor: "pointer",
    width: "fit-content"
  },
  match: {
    backgroundColor: "rgba(255, 255, 255, 0.16)",
    color: "white",
    textTransform: "none",
    "& .MuiChip-label": {
      paddingLeft: "8px",
      paddingRight: "8px"
    },
    "&:focus": {
      backgroundColor: "rgba(255, 255, 255, 0.3)"
    },
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.3)"
    }
  },
  li: {
    paddingLeft: "0px",
    paddingRight: "0px"
  },
  question: {
    fontSize: "16px",
    marginBottom: "24px",
    textTransform: "none",
    whiteSpace: "pre-wrap"
  },
  quoteText: {
    textAlign: "-webkit-auto"
  },

  questionTitle: {
    fontSize: "0.75rem",
    paddingLeft: "24px",
    paddingRight: "24px",
    textTransform: "uppercase",
    color: "#BCD9F1"
  }
}));

export default function FeedbackQuestionPanel({
  index,
  value,
  isTeacher,
  question,
  score,
  showMatch
}) {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const text = useSelector(selectCurrentText);
  const highlightsState = useSelector((state) => state.interactions.highlights);

  const [selectedQuote, setSelectedQuote] = useState(false);

  const highlights = useMemo(() => {
    return highlightsState.filter(
      (highlight) => highlight.interaction_id === question.id
    );
  }, [highlightsState]);

  const renderAnswer = () => {
    let matchScore = score ? score.toFixed(1).toString() : 0;
    return (
      <Box style={{ paddingInline: "16px" }}>
        {Boolean(
          isTeacher &&
            showMatch &&
            question.quotes &&
            question.quotes.length &&
            (question.includeCitation ||
              ["FIND_IN_TEXT"].includes(question.interaction_subtype))
        ) && (
          <Chip
            onClick={() => {
              dispatch(setClickedMatch(true));
            }}
            className={classes.match}
            label={
              matchScore +
              "% " +
              intl.formatMessage({
                defaultMessage: "match",
                id: "task.feedback.match"
              })
            }
          />
        )}
        <List>
          {highlights.map((highlight) => {
            return (
              <ListItem
                key={highlight.id}
                ContainerComponent="li"
                className={classes.li}>
                <Card className={classes.card} elevation={1}>
                  <Typography
                    sx={{
                      paddingBlock: 0,
                      paddingInline: 0,
                      fontSize: 20,
                      color: text.primary,
                      lineHeight: "1.5",
                      marginBlockEnd: 2,
                      textAlign: "-webkit-auto"
                    }}
                    dir="auto">
                    {highlight.title}
                  </Typography>

                  <Typography className={classes.quoteText} dir="auto">
                    {highlight.content}
                  </Typography>
                  <Button
                    onClick={() => {
                      showInText(false, highlight, null, null);
                      setSelectedQuote(highlight);
                    }}
                    className={clsx(classes.quoteRef)}>
                    <FormattedMessage
                      id="show.text"
                      defaultMessage="Show in text"
                    />
                  </Button>
                </Card>
              </ListItem>
            );
          })}
        </List>
      </Box>
    );
  };

  const getQuestionTypeText = () => {
    const validType = questionTypes.filter(
      (el) => el.id === question.interaction_subtype
    );
    return validType[0].txt ?? "";
  };

  const questionTypes = [
    {
      id: INTERACTION_SUBTYPES.FIND_IN_TEXT,
      txt: intl.formatMessage({
        id: "feedback.findInText",
        defaultMessage: "Find in text"
      })
    },
    {
      id: INTERACTION_SUBTYPES.OPEN_ENDED,
      txt: intl.formatMessage({
        id: "feedback.inYourOwnWords",
        defaultMessage: "In your own words"
      })
    },
    {
      id: INTERACTION_SUBTYPES.MULTI_CHOICE,
      txt: intl.formatMessage({
        id: "feedback.multiple",
        defaultMessage: "Multiple choice"
      })
    }
  ];

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-label={`simple-tab-${index}`}>
      {value === index && (
        <Box
          className={clsx(classes.panelHeader, classes.panelHeaderLtr)}
          id="panel1a-header">
          <Typography
            variant="h6"
            className={clsx(classes.questionTitle, classes.questionLtr)}>
            {getQuestionTypeText()}
          </Typography>

          <Box className={classes.panelStageName}>
            <Typography
              variant="body1"
              className={clsx(classes.question, classes.questionLtr)}>
              {question.content}
            </Typography>
            <Divider className={classes.divider} />
            {renderAnswer()}
          </Box>
        </Box>
      )}

      {selectedQuote && (
        <CitationDialog
          setOpenDialog={setSelectedQuote}
          url={text.url}
          location={selectedQuote.cfi}
          highlights={[selectedQuote]}
          openDialog={!!selectedQuote}
          citationTitleFormattedMessage={
            <FormattedMessage
              id="show.location.inText"
              defaultMessage="Show in Text"
            />
          }
        />
      )}
    </Box>
  );
}

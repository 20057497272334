import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router";
import { selectCourseRole } from "../redux/coursesSlice";
import { PangeaSpinner } from "../components/SharedComponents";

import TaskManager from "../components/Tasks/TaskManager/TaskManager";
import CreateAssignmentScreen from "../components/Tasks/CreateTask/CreateAssignmentScreen";
import TaskStatsView from "../components/Tasks/Stats/TaskStatsView";

export default function TasksRoutes() {
  const courseId = useSelector(
    (state) => state.user.userProfile.selectedCourseId
  );
  const courseRole = useSelector(selectCourseRole);

  return (
    <Routes>
      <Route index element={<TaskManager />} />
      <Route path="edit" element={<CreateAssignmentScreen />} />
      <Route
        path="new"
        element={
          courseRole === "Teacher" ? (
            <CreateAssignmentScreen />
          ) : (
            <Navigate to={`/tasks?course_id=${courseId}`} replace />
          )
        }
      />
      <Route path="stats" element={<TaskStatsView />} />
    </Routes>
  );
}

// Dependencies
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { FEATURES, TASK } from "../../consts";
import { httpCallables } from "../../firebase";
import { captureException } from "../../utils/errorHandlers";
import { useQuery } from "../../hooks";
import {
  setAction,
  setAnnotatorMode,
  setBreadcrumbs,
  setIsLoading
} from "../../redux/readerActionsSlice";
import { useFirestoreConnect } from "../../hooks/useFirestoreConnect";

// Redux
import {
  selectPrimarySidebarState,
  selectSecondarySidebarState
} from "../../redux/firestoreSelectors";
import { useSelector, useDispatch } from "react-redux";
import {
  setTask,
  setSelectedTaskId,
  updateSubmissions,
  selectSubmission,
  selectTask
} from "../../redux/tasksSlice";
import {
  selectText,
  setTextUrl,
  setSelectedTextId
} from "../../redux/textsSlice";
import { setInteractions } from "../../redux/interactionsSlice";
import { resetThreadsState } from "../../redux/realtimeInteractionsSlice";
// Components
import StandardTask from "./standardTask/StandardTask";
import { PangeaSpinner } from "../SharedComponents";

import { initializeFeatureLayout } from "../../redux/layoutSlice";
import { useIntl } from "react-intl";
import { selectCourseByTextId } from "../../redux/coursesSlice";
import { fetchConversation } from "../../redux/chatSlice";

// Material UI

function Task() {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { submission_id } = useQuery();
  const intl = useIntl();

  // Redux Selectors

  const userId = useSelector((state) => state.firebase.auth.uid);

  useFirestoreConnect([
    {
      collection: "userProfiles",
      doc: "customConfig",
      subcollections: [{ collection: userId, doc: FEATURES.TASK }],
      storeAs: "customConfig"
      // type: "once"
    }
  ]);

  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const primarySidebarState = useSelector((state) =>
    selectPrimarySidebarState(state)
  );
  const secondarySidebarState = useSelector((state) =>
    selectSecondarySidebarState(state)
  );
  const task = useSelector((state) => selectTask(state, submission.task_id));
  const text = useSelector((state) => selectText(state, task.text_id));

  const isLoading = useSelector((state) => state.readerActions.isLoading);

  const course = useSelector(selectCourseByTextId);

  //Derived state
  const taskType = task.task_type;
  const isStandardTask = taskType === "standard";
  const isGrTask = taskType === "guidedReading";
  const isPrTask = taskType === "peerReview";

  const s_status = TASK.SUBMISSION_STATUS;
  const task_submitted =
    submission.status === s_status.SUBMITTED ||
    submission.status === s_status.GRADED;

  //Behavior
  useEffect(() => {
    dispatch(initializeFeatureLayout());
  }, [dispatch, primarySidebarState, secondarySidebarState]);

  //TODO: remove this once we get rid of actions
  useEffect(() => {
    if (isGrTask) {
      dispatch(setAction("poc"));
    } else {
      dispatch(setAction("task"));
    }
    dispatch(setAnnotatorMode("poc"));
  }, [isGrTask, dispatch]);

  useEffect(() => {
    if (!task.id) return;
    dispatch(setSelectedTaskId(task.id));
  }, [dispatch, task.id]);

  //TODO: remove this once we call epubView directly from the task and pass down the text id as prop - selected text should only be set from the library
  useEffect(() => {
    if (!task.text_id) return;
    dispatch(setSelectedTextId(task.text_id));
  }, [dispatch, task.text_id]);

  useEffect(() => {
    if (!text.id) return;
    httpCallables
      .textFunctions({
        func_name: "getTextDownloadUrl",
        courseId: text.course_id,
        fileUrl: text.file_url,
        textId: text.id,
        textType: text.text_type
      })
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setTextUrl({ url: response.data.payload, text_id: text.id })
          );
        }
      });
  }, [dispatch, text.course_id, text.file_url, text.id]);

  useEffect(() => {
    if (!submission_id) return;
    dispatch(setIsLoading(true));
    const params = {
      func_name: "readSubmission",
      submission_id: Number(submission_id)
    };
    httpCallables.taskFunctions(params).then(({ data }) => {
      try {
        const { success } = data;
        if (success) {
          const { task, submissions, interactions } = JSON.parse(data.payload);
          dispatch(setTask({ task, submissions }));
          dispatch(updateSubmissions(submissions));
          dispatch(setInteractions(interactions));
        } else {
          // TODO: alert user?
          let error = new Error(`READ_SUBMISSION_FAILED`);
          error.message = data.error;
          error.data = { submission_id };

          throw error;
        }
      } catch (err) {
        captureException(err);
        //in case of error, go back to tasks page
        navigate("/tasks");
      } finally {
        dispatch(setIsLoading(false));
      }
    });

    return () => {
      dispatch(resetThreadsState());
    };
  }, [submission_id, dispatch, history]);

  useEffect(() => {
    if (submission_id && task?.text_id) {
      dispatch(fetchConversation({ submission_id, text_id: task.text_id }));
    }
  }, [dispatch, submission_id, task]);

  useEffect(() => {
    let parts = [];
    parts.push({
      url: course && course.id ? `/tasks?course_id=${course.id}` : "/tasks",
      resetCourse: true,
      text: intl.formatMessage({
        id: "appBar.tasks",
        defaultMessage: "Assignments"
      })
    });
    course &&
      course.name &&
      parts.push({
        url: `/tasks?course_id=${task.course_id}`,
        text: course.name
      });
    task &&
      task.name &&
      parts.push({
        url: `/tasks?course_id=${task.course_id}&task_id=${task.id}`,
        text: task.name
      });
    task &&
      isPrTask &&
      task_submitted &&
      parts.push({
        url: `/task?submission_id=${submission.id}`,
        text: submission.user_name
      });
    dispatch(
      setBreadcrumbs({ breadcrumbs: parts, blue: true, showTextMenu: true })
    );
  }, [task.id, task.name, course.name, course.id]);

  if (isLoading || !taskType) return <PangeaSpinner />;
  else if (isStandardTask) return <StandardTask />;
  else if (isGrTask) return <StandardTask />;
}

Task.propTypes = {};

export default Task;

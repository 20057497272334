// Dependencies
import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import clsx from "clsx";
// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import {
  selectAnswerComment,
  selectSubmissionQuestions
} from "../../../redux/interactionsSlice";

// Components
import ConceptChip from "../ConceptChip";

import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Chip,
  Divider
} from "@mui/material";
import FeedbackQuestion from "./FeedbackQuestion";
import { getWordCount } from "./utils";
import { ScrollBox } from "../../SharedComponents";

//Styles
const useStyles = makeStyles((theme) => ({
  correct: {
    color: theme.palette.secondary.dark,
    borderColor: theme.palette.secondary.dark
  },
  correctInfo: {
    color: theme.palette.secondary.dark,
    borderColor: theme.palette.secondary.dark
  },
  incorrect: {
    color: theme.palette.error.dark,
    borderColor: theme.palette.error.dark
  },
  container: {
    height: "100%",
    width: "100%",
    paddingTop: theme.spacing(5),
    display: "flex",
    justifyContent: "flex-end"
  },
  answer: {
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    flex: "1",
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  feedback: {
    flex: "0 0 450px",
    width: "450px",
    marginRight: "24px"
  },
  conceptContainer: {
    marginBotton: theme.spacing(1),
    "& .MuiChip-root": {
      height: theme.spacing(3),
      "&:first-child": {
        marginLeft: "0"
      }
    }
  },
  answerContainer: {
    fontSize: "20px"
  }
}));

function FeedbackScreen({
  task,
  isTeacher,
  showMatch,
  conceptMapping,
  highlightMatch,
  selectedQuestionIndex
}) {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  // Redux state
  const linkTarget = useSelector((state) => state.link.linkTarget);
  const selected = useSelector((state) => state.link.selected);
  const questions = useSelector(selectSubmissionQuestions);

  const question = questions?.[selectedQuestionIndex] || {};
  const answer = useSelector((state) =>
    selectAnswerComment(state, question.id)
  );

  // Behavior
  function renderAnswer() {
    return (
      <Box className={classes.answer}>
        <Box sx={{ width: "100%" }}>
          <Typography variant="h6">
            {intl.formatMessage({
              id: "feedback.answer",
              defaultMessage: "Answer"
            })}
          </Typography>
          <Divider sx={{ marginTop: "16px", marginBottom: "16px" }} />
          {question.interaction_subtype === "OPEN_ENDED" && (
            <Box>
              {isTeacher && conceptMapping && conceptMapping.length > 0 && (
                <Box className={classes.conceptContainer}>
                  {conceptMapping.map((concept, i) => {
                    return (
                      <ConceptChip
                        key={i}
                        found={concept.found}
                        concept={concept.concept}
                      />
                    );
                  })}
                </Box>
              )}
              {answer.content && (
                <Box sx={{ marginTop: "16px" }}>
                  <Typography
                    variant="body1"
                    sx={{ color: "text.primary", marginBottom: "40px" }}>
                    {answer.content}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary", paddingBottom: "40px" }}>
                    {intl.formatMessage(
                      {
                        id: "feedback.wordCount",
                        defaultMessage: "words"
                      },
                      { count: getWordCount(answer.content) }
                    )}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          {question.interaction_subtype === "FIND_IN_TEXT" && (
            <Box sx={{ marginTop: "16px" }}>
              <Typography
                variant="body1"
                sx={{ color: "text.primary", marginBottom: "40px" }}>
                {intl.formatMessage({
                  id: "feedback.findInTextAnswer",
                  defaultMessage:
                    "This is a “find in text” question, no written response is required."
                })}
              </Typography>
            </Box>
          )}
          {question.interaction_subtype === "MULTI_CHOICE" && (
            <Box>
              <Typography
                variant="body1"
                sx={{ color: "text.primary", marginBottom: "8px" }}>
                {intl.formatMessage({
                  id: "feedback.multipleChoiceAnswer",
                  defaultMessage: "Choose the correct answer"
                })}
              </Typography>
              <RadioGroup name="answer" value={answer.choice}>
                {question.options.map((opt, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}>
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            color: "text.primary"
                          },
                          "&.Mui-disabled": {
                            "& .MuiFormControlLabel-label": {
                              color: "text.primary"
                            }
                          },
                          flex: "1"
                        }}
                        value={index}
                        key={index}
                        control={
                          <Radio
                            disabled
                            sx={{
                              color: "text.primary",
                              "&.Mui-disabled": {
                                color: "text.primary"
                              }
                            }}
                            checked={index === parseInt(answer.choice)}
                          />
                        }
                        label={opt}
                      />
                      <Box
                        sx={{
                          flex: "0 0 85px",
                          display: "flex",
                          justifyContent: "flex-end"
                        }}>
                        {index === parseInt(answer.choice) &&
                          parseInt(question.shouldSelect) === index && (
                            <Chip
                              variant="outlined"
                              className={classes.correct}
                              label={intl.formatMessage({
                                id: "task.multi.correct",
                                defaultMessage: "Correct"
                              })}
                            />
                          )}
                        {index !== parseInt(answer.choice) &&
                          parseInt(question.shouldSelect) === index && (
                            <Chip
                              variant="outlined"
                              className={classes.correctInfo}
                              label={intl.formatMessage({
                                id: "task.multi.correct",
                                defaultMessage: "Correct"
                              })}
                            />
                          )}

                        {index === parseInt(answer.choice) &&
                          parseInt(question.shouldSelect) !== index && (
                            <Chip
                              variant="outlined"
                              className={classes.incorrect}
                              label={intl.formatMessage({
                                id: "task.multi.incorrect",
                                defaultMessage: "Incorrect"
                              })}
                            />
                          )}
                      </Box>
                    </Box>
                  );
                })}
              </RadioGroup>
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  // Render
  return (
    <ScrollBox>
      <Box className={classes.container}>
        {question && renderAnswer()}
        <Box className={clsx(classes.feedback)}>
          <FeedbackQuestion
            key={selectedQuestionIndex}
            index={selectedQuestionIndex}
            task={task}
            showMatch={showMatch}
            question={question}
            conceptMapping={conceptMapping}
            highlightMatch={highlightMatch}
          />
        </Box>
      </Box>
    </ScrollBox>
  );
}

FeedbackScreen.propTypes = {
  task: PropTypes.shape({
    task_type: PropTypes.string.isRequired
  })
};

export default FeedbackScreen;

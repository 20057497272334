// Dependencies
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { isPast, isToday, endOfDay } from "date-fns";

// Redux dependencies
import { useDispatch } from "react-redux";
import { addSnackbar } from "../../../redux/snackbarSlice";

// Components
import NavigationButtons from "./NavigationButtons";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Divider, Typography } from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";

//Styles
const useStyles = makeStyles(() => ({
  section: {
    marginBottom: "50px"
  },
  sectionContent: {
    marginTop: "16px"
  },
  divider: {
    backgroundColor: "#dedede",
    marginBottom: "8px",
    marginTop: "8px"
  },
  deadline: {
    marginTop: "8px",
    "& .MuiPickersDay-current": {
      color: "#168fee"
    },
    "& .MuiPickersDay-daySelected": {
      color: "#fff"
    }
  },
  popover: {
    left: "20px"
  }
}));

export default function CreateAssignmentPublish({
  taskType,
  deadline,
  setDeadline,
  collaboratorDeadline,
  readOnly = false,
  activeStep,
  setActiveStep,
  publishTask,
  saveTask,
  setIsDirty,
  isEdit
}) {
  //Hooks
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  //Redux state

  const [showErrors, setShowErrors] = useState(false);

  //Variables
  const deadlineValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.enterDeadline",
    defaultMessage: "Set a due date for this assignment"
  });
  const deadlineValueValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.deadlinePast",
    defaultMessage: "The due date you selected has already passed"
  });

  // Behavior
  function validateForm() {
    let message = "";
    // For standart tasks, check if there is a deadline
    if (
      (taskType === "standard" || taskType === "guidedReading") &&
      !deadline
    ) {
      message = deadlineValidationMessage;
    } else if (
      taskType === "peerReview" &&
      (!deadline || !collaboratorDeadline)
    ) {
      message = deadlineValidationMessage;
      // If there is a deadline...
    }
    // Check if the deadline's day is today or after
    else if (isPast(deadline) || isToday(deadline)) {
      message = deadlineValueValidationMessage;
    }
    if (message === "") {
      setIsDirty(false);
      return true;
    } else {
      setShowErrors(true);
      dispatch(
        addSnackbar({
          message: message,
          severity: "error"
        })
      );
      return false;
    }
  }

  //Render
  return (
    <Box data-testid="create-assignment-publish">
      <Box className={classes.section}>
        <Typography variant="h6">
          {intl.formatMessage({
            id: "task.create.publish.timeFrame",
            defaultMessage: "Time Frame"
          })}
        </Typography>
        <Divider className={classes.divider} />
        <Box className={classes.sectionContent}>
          <DatePicker
            id="deadline"
            name="deadline"
            disabled={readOnly}
            label={
              taskType === "peerReview"
                ? // Peer Review
                  intl.formatMessage({
                    id: "tasks.responderDeadline",
                    defaultMessage: "Reflection due date"
                  })
                : // All other Tasks
                  intl.formatMessage({
                    id: "tasks.deadline",
                    defaultMessage: "Due date"
                  })
            }
            openTo="day"
            className={classes.deadline}
            DialogProps={{ className: classes.deadline }}
            minDate={new Date()}
            value={deadline}
            onChange={(e) => {
              setDeadline(endOfDay(e));
            }}
            leftArrowButtonProps={{ "aria-label": "previous month" }}
            rightArrowButtonProps={{ "aria-label": "next month" }}
            slotProps={{
              textField: {
                inputProps: {
                  "data-testid": "deadline-datepicker-input"
                },
                error: showErrors && !deadline,
                onBlur: () => setShowErrors(false)
              },
              inputAdornment: {
                sx: {
                  "& .MuiSvgIcon-root": {
                    color:
                      showErrors && !deadline ? "error.main" : "action.active"
                  }
                }
              }
            }}
          />
          {deadline && (
            <TimePicker
              id="deadline"
              name="deadline"
              disabled={readOnly}
              sx={{ marginInlineStart: "30px" }}
              label={
                taskType === "peerReview"
                  ? // Peer Review
                    intl.formatMessage({
                      id: "tasks.responderDeadline",
                      defaultMessage: "Reflection due time"
                    })
                  : // All   other Tasks
                    intl.formatMessage({
                      id: "tasks.dueTime",
                      defaultMessage: "Due time"
                    })
              }
              className={classes.deadline}
              DialogProps={{ className: classes.deadline }}
              value={deadline}
              onChange={(e) => {
                setDeadline(e);
              }}
              slotProps={{
                textField: {
                  inputProps: {
                    "data-testid": "deadline-timepicker-input"
                  }
                }
              }}
            />
          )}
        </Box>
      </Box>

      <NavigationButtons
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        publishTask={publishTask}
        saveTask={saveTask}
        validateForm={validateForm}
        isEdit={isEdit}
        readOnly={readOnly}
      />
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useBlocker } from "react-router";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import makeStyles from "@mui/styles/makeStyles";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { selectTextDirection } from "../../../redux/firestoreSelectors";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "relative",
    width: "50%",
    padding: "10px"
  },
  modalCancel: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer"
  },
  modalActions: {
    "& button": {
      fontSize: "inherit"
    }
  },
  buttonDiscard: {
    color: "#787877"
  },
  button: {
    textTransform: "none"
  }
}));

export default function RouteLeavingGuard({
  when,
  setTask,
  isDirty,
  setIsDirty,
  msg,
  additionalStep,
  additionalStepButton,
  navigate
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [block, setBlock] = useState(true);
  const classes = useStyles();
  const intl = useIntl();
  const textDirection = useSelector(selectTextDirection);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (when && block && currentLocation.pathname !== nextLocation.pathname) {
      setNextLocation(nextLocation);
      return true;
    }
    return false;
  });

  useEffect(() => {
    const handleUnload = (e) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [isDirty]);

  useEffect(() => {
    if (blocker.state === "blocked") {
      setModalVisible(true);
    }
  }, [blocker]);

  const closeModal = () => {
    setModalVisible(false);
    blocker.reset();
  };

  const handleConfirmNavigationClick = async () => {
    setModalVisible(false);
    setBlock(false);
    if (nextLocation) {
      blocker.reset();
      navigate(nextLocation.pathname + nextLocation.search);
    }
  };

  const handlePassedInStep = () => {
    setModalVisible(false);
    setBlock(false);
    if (nextLocation) {
      blocker.reset();
      additionalStep();
    }
  };

  return (
    modalVisible && (
      <Dialog
        open={modalVisible}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          className: classes.modal,
          style: {
            direction: textDirection
          }
        }}>
        <ClearIcon className={classes.modalCancel} onClick={closeModal} />
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage
            id="task.beforeYouLeave"
            defaultMessage="Before you leave"
          />
        </DialogTitle>
        <DialogContent>
          <div id="alert-dialog-description">
            {msg ||
              intl.formatMessage({
                id: "task.saveAsDraft",
                defaultMessage: "Save task as draft?"
              })}
          </div>
        </DialogContent>
        <DialogActions className={classes.modalActions}>
          <Button
            onClick={handleConfirmNavigationClick}
            className={clsx(classes.button, classes.buttonDiscard)}>
            <FormattedMessage id="discard" defaultMessage="Discard" />
          </Button>
          {additionalStep && additionalStepButton && (
            <Button
              className={classes.button}
              onClick={handlePassedInStep}
              color="primary"
              autoFocus>
              {additionalStepButton}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  );
}

RouteLeavingGuard.propTypes = {
  when: PropTypes.bool.isRequired,
  setTask: PropTypes.func,
  isDirty: PropTypes.bool.isRequired,
  setIsDirty: PropTypes.func,
  msg: PropTypes.string,
  additionalStep: PropTypes.func,
  additionalStepButton: PropTypes.string,
  navigate: PropTypes.func.isRequired
};

import React, { useState } from "react";
import { Dialog, DialogActions, Button, Box, TextField } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import store from "../../../../redux/store.js";
import { addSnackbar } from "../../../../redux/snackbarSlice.js";
import { setIsOpen as setIsEmailModalOpen } from "../../../../redux/emailModalSlice.js";
import { useDispatch } from "react-redux";

const IntroMailDatePicker = ({ minDate, maxDate, data, saveNewData }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [tempDate, setTempDate] = useState(data.scheduleTime);
  const formatDate = (date) => (date ? format(date, "dd/MM/yyyy") : null);
  const formatSnackBarDate = (date) =>
    date ? format(date, "MMM d, yyyy 'at' h a") : null;

  const handleClose = () => setIsOpen(false);

  const handleSchedule = () => {
    saveNewData({ ...data, scheduleTime: tempDate });
    dispatch(setIsEmailModalOpen(false));
    handleClose();
    store.dispatch(
      addSnackbar({
        message: `Email will be sent on ${formatSnackBarDate(tempDate)}`,
        actions: [
          {
            intlId: "showme",
            intlDefaultMsg: "Show Me",
            callBack: "showIntroEmailModal"
          }
        ]
      })
    );
  };
  const handleCancel = () => {
    saveNewData({ ...data, scheduleTime: null });
    handleClose();
  };

  return (
    <Box>
      {/* This is the DatePicker trigger */}
      <TextField
        label="Schedule send"
        placeholder="DD/MM/YYYY"
        value={data.scheduleTime ? formatDate(data.scheduleTime) : ""}
        onClick={() => setIsOpen(true)}
        fullWidth
        size={"small"}
        sx={{ cursor: "pointer" }}
        InputProps={{ readOnly: true }}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setIsOpen(true);
          }
        }}
      />

      {/* Dialog with StaticDatePicker and custom actions */}
      <Dialog open={isOpen} onClose={handleClose}>
        <StaticDatePicker
          value={tempDate}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(newDate) => {
            newDate?.setHours(8, 0, 0, 0);
            setTempDate(newDate);
          }}
          slotProps={{
            actionBar: {
              actions: []
            }
          }}
        />

        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSchedule} variant="contained" color="primary">
            Schedule
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default IntroMailDatePicker;

// Dependencies
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useIntl } from "react-intl";
import { format } from "date-fns";

import clsx from "clsx";
import { useQuery, useConvertToTimezone } from "../../../hooks";

// Redux Dependencies
import { selectCourse } from "../../../redux/coursesSlice";
import { selectTask } from "../../../redux/tasksSlice";

//Components
import TaskStatusChip from "./TaskStatusChip";
import { navigateToTaskHandler } from "../../../utils/navigateToTaskHandler";

// Material UI
import makeStyles from "@mui/styles/makeStyles";
import { TableRow, TableCell, IconButton, Chip } from "@mui/material";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import TooltipWithIntl from "../../SharedComponents/tooltip/TooltipWithIntl";
import SubmittingChip from "../../SharedComponents/SubmittingChip";

// Styles
const useStyles = makeStyles((theme) => ({
  tasksHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: "60px 0"
  },
  chip: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.text.blue
    }
  },
  cell: {
    height: "24px"
  },
  active: {
    backgroundColor: "transparent",
    color: "#0eac72",
    border: "1px solid #0eac72"
  },
  pending: {
    backgroundColor: "transparent",
    border: "1px solid black"
  },
  delivered: {
    backgroundColor: "#0eac72"
  },
  graded: {
    backgroundColor: "#0eac72"
  },
  new: {
    border: "1px solid black"
  },
  left: {
    textAlign: "left"
  },
  right: {
    textAlign: "right"
  },
  submitted: {
    backgroundColor: "transparent",
    color: "#0eac72",
    border: "1px solid #0eac72"
  },
  received: {
    color: theme.palette.primary.contrast,
    backgroundColor: theme.palette.primary.main
  },
  createBtn: {
    borderRadius: "4px",
    valign: "bottom",
    position: "absolute",
    bottom: "0px",
    right: "0px"
  },
  container: {
    width: "100w",
    height: "100vh"
  },
  clickableTask: {
    cursor: "pointer",
    "&:focus, &:hover": {
      backgroundColor: "rgba(46, 125, 50, 0.04)"
    },
    height: "70px"
  },
  newTask: {
    fontWeight: "800"
  },
  dialog: {
    backgroundColot: "red"
  },
  tableContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "24px"
  }
}));
export default function SubmissionsListItem({ submission }) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { course_id } = useQuery();

  // Redux State

  const course = useSelector((state) => selectCourse(state, Number(course_id)));
  const task = useSelector((state) => selectTask(state, submission.task_id));
  const currentUserId = useSelector((state) => state.user.auth.uid);

  const getLargerDate = (date1, date2) => {
    try {
      return new Date(date1) > new Date(date2) ? date1 : date2;
    } catch {
      return date1 ? date1 : date2;
    }
  };

  const due_date = getLargerDate(submission.due_date, task.original_due_date);
  const [formattedDueDate, TZdueDate] = useConvertToTimezone(
    due_date,
    Intl.DateTimeFormat().resolvedOptions().timeZone,
    "d MMM HH:mm"
  );

  const formattedSubmissionDate = submission.submission_date
    ? format(submission.submission_date, "MMM do")
    : null;

  // Derived State
  const isOwner = submission.owner === currentUserId;

  const isNew = (task) => {
    let now = new Date();

    return now < TZdueDate && submission.status === "Pending";
  };

  function handleClick() {
    navigateToTaskHandler(task, submission, navigate, dispatch);
  }

  if (!isOwner) return null;
  return (
    <TableRow
      data-test-class="task-row"
      className={clsx(isNew(task) && classes.newTask, classes.clickableTask)}
      onClick={handleClick}
      onKeyPress={(e) => {
        if (e.key === " ") handleClick();
      }}
      role="link"
      tabIndex="0">
      <TableCell
        className={clsx(classes.cell, isNew(task) && classes.newTask)}
        component="th"
        scope="row">
        {task.name}
      </TableCell>

      {submission.status === "SUBMITTING" ? (
        <TableCell className={clsx(classes.cell)} align="left">
          <SubmittingChip />
        </TableCell>
      ) : (
        <>
          <TableCell
            className={clsx(classes.cell, isNew(task) && classes.newTask)}
            align="left">
            <TaskStatusChip
              task={task}
              submission={submission}
              userRole="Student"
              onClick={handleClick}
            />
          </TableCell>
        </>
      )}
      <TableCell
        className={clsx(classes.cell, isNew(task) && classes.newTask)}
        align="left">
        {submission.is_checked && (
          <Chip
            color="primary"
            label="Recieved"
            size="small"
            className={clsx(classes.chip, classes.received)}
          />
        )}
      </TableCell>
      <TableCell
        className={clsx(classes.cell, isNew(task) && classes.newTask)}
        align="left">
        {formattedSubmissionDate}
      </TableCell>
      <TableCell
        className={clsx(classes.cell, isNew(task) && classes.newTask)}
        align="left">
        {format(due_date, "MMM do")}
      </TableCell>
      <TableCell
        className={clsx(
          classes.cell,
          isNew(task) && classes.newTask,
          classes.link,
          classes.gradeWithHeatmap
        )}
        align="left">
        {formattedSubmissionDate && task.hasTeacherQuestions && (
          <TooltipWithIntl
            intlStringId="task.see.heatmap"
            defaultMessage="View assignment's heatmap"
            placement="bottom">
            <IconButton
              onClick={(e) => {
                navigate(`/task/stats?task_id=${task.id}`);
                e.stopPropagation();
                e.preventDefault();
              }}
              className={classes.actionIcon}
              aria-label="Assignment heatmap"
              size="large">
              <MapOutlinedIcon />
            </IconButton>
          </TooltipWithIntl>
        )}
      </TableCell>
    </TableRow>
  );
}

// Dependencies
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { httpCallables } from "../../../firebase";
import { useNavigate, useSearchParams } from "react-router";
import { captureException } from "../../../utils/errorHandlers";
import { useQuery } from "../../../hooks";

// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import {
  selectSubmission,
  selectTask,
  updateSubmission
} from "../../../redux/tasksSlice";
import { sendFeedback } from "../../../redux/taskSlice";
import {
  selectSubmissionQuestions,
  selectSubmissionTaskFeedback,
  updateFeedbacks
} from "../../../redux/interactionsSlice";

// MUI
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  TextField,
  Typography
} from "@mui/material";

// Components
import NewTextEditor from "../../SharedComponents/textEditor/NewTextEditor";
import { addSnackbar } from "../../../redux/snackbarSlice";

export default function FeedbackSubmissionModal({
  showSubmissionDialog,
  setShowSubmissionDialog,
  feedbacks,
  setIsLoading
}) {
  // Hooks
  const intl = useIntl();
  const dispatch = useDispatch();
  const { submission_id } = useQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // Redux State
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const task = useSelector((state) => selectTask(state, submission.task_id));
  const taskEval = useSelector((state) =>
    selectSubmissionTaskFeedback(state, Number(submission_id))
  );
  const questions = useSelector(selectSubmissionQuestions);
  const answers = useSelector((state) => state.interactions.answers);
  const userId = useSelector((state) => state.user.auth.uid);

  // Ephemeral State
  const [feedbackComment, setFeedbackComment] = useState("");
  const [evalTitle, setEvalTitle] = useState("");
  const [validPointsPerQuestion, setValidPointsPerQuestion] = useState(true);
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [grade, setGrade] = useState(0);
  const [feedbackSummary, setFeedbackSummary] = useState("");
  const [continueToNextStudent, setContinueToNextStudent] = useState(true);
  const [feedbackCommentKey, setFeedbackCommentKey] = useState("");

  // Derived State
  const isChecked = submission.is_checked;
  const isCheckedReadOnly = isChecked && submission.grader !== userId;
  const totalPoints = questions.reduce((accumulator, current) => {
    // TODO: check that points can only be a number of a string representing a number and get rid of the check
    const points =
      current.points && !isNaN(current.points) ? Number(current.points) : 0;
    return accumulator + points;
  }, 0);
  const submissions = useMemo(
    () =>
      task.submissions
        ?.filter((s) => s.status === "Submitted" || s.status === "Graded")
        .sort((a, b) => a.user_name.localeCompare(b.user_name)) ?? [],
    [task.submissions]
  );
  const submissionIndex = submissions.findIndex((s) => s.id === submission.id);

  // Behavior

  useEffect(() => {
    if (taskEval?.id) {
      setEvalTitle(taskEval.title || "");
      setFeedbackComment(taskEval.content || "");
    }
  }, [taskEval?.id]);

  useEffect(() => {
    setGrade(
      isChecked
        ? submission.grade
        : feedbacks.reduce((acc, curr) => {
            return acc + (Number(curr.points) || 0);
          }, 0)
    );

    composeFeedbackSummary();
  }, [feedbacks]);

  useEffect(() => {
    if (feedbackComment && !feedbackCommentKey) {
      setFeedbackCommentKey(`feedback-comment-${feedbackComment}`);
    }
  }, [feedbackComment]);
  const composeFeedbackSummary = () => {
    // If there are no points assigned to the assignment at all, treat all points as valid
    const hasAssignmentPoints = totalPoints > 0;

    const pointsIndexes = feedbacks
      .map((f, index) => {
        if (!hasAssignmentPoints) {
          // If assignment has no points, don't include any points in summary
          return null;
        }
        // Only include points if they are numbers and the question has points
        const questionPoints = questions[index]?.points;
        const hasConfiguredPoints =
          questionPoints &&
          !isNaN(questionPoints) &&
          Number(questionPoints) > 0;
        return typeof f.points === "number" && hasConfiguredPoints
          ? index + 1
          : null;
      })
      .filter((f) => f !== null);

    const commentsIndexes = feedbacks
      .map((f, index) =>
        f.content && f.content.trim() !== "" ? index + 1 : null
      )
      .filter((f) => f !== null);

    function formatWithOxfordComma(items) {
      if (items.length === 0) return "";
      if (items.length === 1) return items[0];
      if (items.length === 2) return `${items[0]} and ${items[1]}`;
      const lastItem = items[items.length - 1];
      const otherItems = items.slice(0, -1).join(", ");
      return `${otherItems}, and ${lastItem}`;
    }

    const pointsString = formatWithOxfordComma(pointsIndexes);
    const commentsString = formatWithOxfordComma(commentsIndexes);

    if (!hasAssignmentPoints && !commentsString) {
      setFeedbackSummary(
        intl.formatMessage({
          id: "feedback.submissionSummaryNoCommentsNoPoints",
          defaultMessage: "You have added no comments to the questions."
        })
      );
      return;
    } else {
      const summary = `${
        hasAssignmentPoints
          ? pointsString
            ? intl.formatMessage(
                {
                  id: "feedback.submissionSummaryPoints",
                  defaultMessage: "You have added points to questions {points}."
                },
                { points: pointsString }
              )
            : intl.formatMessage({
                id: "feedback.submissionSummaryNoPoints",
                defaultMessage: "You have added no points to questions."
              })
          : ""
      } ${
        commentsString
          ? intl.formatMessage(
              {
                id: "feedback.submissionSummaryComments",
                defaultMessage:
                  "You have added comments to questions {comments}"
              },
              { comments: commentsString, begin: !hasAssignmentPoints }
            )
          : intl.formatMessage(
              {
                id: "feedback.submissionSummaryNoComments",
                defaultMessage: "You have added no comments to questions"
              },
              { begin: !hasAssignmentPoints }
            )
      }`;
      setFeedbackSummary(summary);
    }
  };

  const submitFeedback = async () => {
    setIsLoading(true);

    await httpCallables
      .taskFunctions({
        func_name: "submitFeedback",
        id: Number(submission_id),
        content: feedbackComment,
        title: evalTitle,
        grade: Number(grade),
        questions: questions,
        answers: answers
      })
      .then(() => {
        dispatch(
          updateSubmission({
            id: Number(submission_id),
            updates: [
              { key: "is_checked", value: true },
              { key: "grade", value: Number(grade) },
              { key: "grader", value: userId }
            ]
          })
        );
        dispatch(
          addSnackbar({
            message: intl.formatMessage({
              id: "feedback.feedbackSent",
              defaultMessage: "Feedback sent"
            })
          })
        );
        const nextSubmisionId = submissions[submissionIndex + 1]?.id;
        if (continueToNextStudent && nextSubmisionId) {
          searchParams.set("submission_id", nextSubmisionId);
          setSearchParams(searchParams);
        } else {
          navigate("/tasks");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        dispatch(
          updateSubmission({
            id: Number(submission_id),
            updates: [{ key: "is_checked", value: false }]
          })
        );
        captureException(err);
      });
  };

  const updateFeedback = async () => {
    setIsLoading(true);
    const questionsFeedbacks = feedbacks.map((f) => ({
      id: f.id,
      points: f.interaction_subtype === "QUESTION" ? f.points : Number(grade),
      content:
        f.interaction_subtype === "QUESTION" ? f.content : feedbackComment,
      rich_text: f.rich_text,
      title: f.interaction_subtype === "QUESTION" ? f.content : evalTitle,
      word_count: f.word_count,
      func_name: "updateInteraction"
    }));

    const updateInteractions = questionsFeedbacks.map((f) =>
      httpCallables.interactionFunctions(f)
    );
    const updateSubmission = httpCallables.taskFunctions({
      func_name: "updateFeedback",
      id: Number(submission_id),
      content: feedbackComment,
      title: evalTitle,
      grade: Number(grade),
      questions,
      answers
    });
    await Promise.all(updateInteractions, updateSubmission);
    updateFeedbacksState();

    setIsLoading(false);
    dispatch(
      addSnackbar({
        message: intl.formatMessage({
          id: "feedback.feedbackUpdated",
          defaultMessage: "Feedback updated"
        })
      })
    );
    const nextSubmisionId = submissions[submissionIndex + 1]?.id;
    if (continueToNextStudent && nextSubmisionId) {
      searchParams.set("submission_id", nextSubmisionId);
      setSearchParams(searchParams);
    }
  };

  const updateFeedbacksState = () => {
    const questionsFeedbacks = feedbacks.map((f) => {
      if (f.interaction_subtype === "TASK") {
        return {
          ...f,
          points: Number(grade),
          content: feedbackComment,
          rich_text: f.rich_text,
          title: evalTitle,
          word_count: f.word_count
        };
      } else {
        return f;
      }
    });
    dispatch(updateFeedbacks(questionsFeedbacks));
    dispatch(
      updateSubmission({
        id: Number(submission_id),
        updates: [{ key: "grade", value: Number(grade) }]
      })
    );
  };

  const renderTitle = () => {
    const handleBlur = (event) => {
      setIsTitleEditing(false);
      setEvalTitle(event.target.value);
    };

    const handleKeyDown = (event) => {
      if (event.key === "Enter" || event.key === " ") {
        event.preventDefault();
        setIsTitleEditing(true);
      }
    };

    if (isTitleEditing) {
      return (
        <TextField
          value={evalTitle}
          onChange={(event) => setEvalTitle(event.target.value)}
          onBlur={handleBlur}
          placeholder={intl.formatMessage({
            id: "feedback.title",
            defaultMessage: "Title (optional)"
          })}
          variant="outlined"
          size="small"
          autoFocus
          fullWidth
          sx={{ marginBottom: "12px" }}
        />
      );
    }

    // Don't render anything if checked and no title
    if (isCheckedReadOnly && !evalTitle) {
      return null;
    }

    return (
      <Box
        onClick={() => !isCheckedReadOnly && setIsTitleEditing(true)}
        onKeyDown={handleKeyDown}
        sx={{
          cursor: isCheckedReadOnly ? "text" : "pointer",
          display: "flex",
          minHeight: "52px",
          alignItems: "center"
        }}>
        <Typography
          tabIndex={0}
          variant="body2"
          role="button"
          color={isCheckedReadOnly ? "text.primary" : "text.secondary"}
          sx={{
            display: "inline-block",
            marginBottom: "16px",
            fontSize: "20px",
            "&:hover": {
              backgroundColor: isCheckedReadOnly
                ? "rgba(0, 0, 0, 0)"
                : "rgba(0, 0, 0, 0.04)"
            }
          }}>
          {!isCheckedReadOnly
            ? evalTitle ||
              intl.formatMessage({
                id: "feedback.title",
                defaultMessage: "Title (optional)"
              })
            : evalTitle}
        </Typography>
      </Box>
    );
  };

  return (
    <Dialog
      open={showSubmissionDialog}
      PaperProps={{
        style: {
          borderRadius: "12px",
          border: 1,
          borderColor: "rgba(0, 0, 0, 0.23)",
          boxShadow: 3,
          padding: "24px",
          width: "700px"
        }
      }}
      onClose={() => {
        dispatch(sendFeedback(false));
        setShowSubmissionDialog(false);
      }}
      aria-labelledby="form-dialog-title">
      <DialogContent sx={{ padding: 0 }}>
        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
            marginBottom: "8px",
            fontSize: "12px"
          }}>
          {intl.formatMessage({
            id: "feedback.overallEvaluation",
            defaultMessage: "OVERALL EVALUATION"
          })}
        </Typography>
        {renderTitle()}
        {isCheckedReadOnly ? (
          <Typography sx={{ marginBottom: "28px" }}>
            {feedbackComment}
          </Typography>
        ) : (
          <Box
            sx={{
              marginBottom: "24px",
              "& .editorContainer ": { padding: "16px" },
              "& .editor ": { minHeight: "100px" }
            }}>
            <NewTextEditor
              key={feedbackCommentKey}
              initialContent={feedbackComment}
              onContentChange={(content) => {
                setFeedbackComment(content.plainText);
              }}
              disabled={isCheckedReadOnly}
              placeholder={intl.formatMessage({
                id: "feedback.typeYourFeedback",
                defaultMessage: "Type your feedback..."
              })}
            />
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "24px"
          }}>
          {!!totalPoints && (
            <TextField
              sx={{ maxWidth: "80px", width: "100px", marginRight: "16px" }}
              variant="outlined"
              value={
                isCheckedReadOnly
                  ? submission.grade !== null
                    ? submission.grade
                    : ""
                  : grade
              }
              onChange={(e) => {
                setGrade(Math.round(e.target.value));
                if (e.target.value <= totalPoints || !totalPoints) {
                  setValidPointsPerQuestion(true);
                } else setValidPointsPerQuestion(false);
              }}
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              label={intl.formatMessage({
                id: "feedback.points",
                defaultMessage: "Points"
              })}
              disabled={isCheckedReadOnly}
              slotProps={{
                input: {
                  readOnly: isCheckedReadOnly,
                  style: { height: "36px" }
                },

                inputLabel: { shrink: true }
              }}
            />
          )}
          {!!totalPoints && (
            <Typography component="span" sx={{ color: "text.secondary" }}>
              {intl.formatMessage(
                {
                  id: "feedback.outOfPoints",
                  defaultMessage: "out of points"
                },
                { points: totalPoints }
              )}
            </Typography>
          )}
          {!validPointsPerQuestion && (
            <Typography
              component="span"
              sx={{ color: "#D32F2F", marginLeft: "8px" }}>
              {intl.formatMessage({
                id: "feedback.pointsExceeded",
                defaultMessage: "Maximum points exceeded"
              })}
            </Typography>
          )}
        </Box>
        {!isChecked && (
          <Alert severity="info" sx={{ marginBottom: "24px" }}>
            {feedbackSummary}
          </Alert>
        )}
        <Divider sx={{ marginBottom: "24px" }} />
      </DialogContent>
      <DialogActions sx={{ padding: 0 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%"
          }}>
          {!isCheckedReadOnly ? (
            submissionIndex < submissions.length - 1 ? (
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={continueToNextStudent}
                      onChange={(event) =>
                        setContinueToNextStudent(event.target.checked)
                      }
                      inputProps={{ "aria-label": "Next student" }}
                    />
                  }
                  label={`${intl.formatMessage({
                    id: "feedback.submissionContinueToNextStudent",
                    defaultMessage: "Continue to next student"
                  })} ${submissionIndex + 2} / ${submissions.length}`}
                />
              </Box>
            ) : (
              <Typography>
                Student {submissionIndex + 1} / {submissions.length}
              </Typography>
            )
          ) : (
            <Box></Box>
          )}
          <Box>
            {!isCheckedReadOnly && (
              <Button
                sx={{ fontSize: "16px", color: "#787877" }}
                onClick={() => {
                  setShowSubmissionDialog(false);
                  dispatch(sendFeedback(false));
                }}>
                {intl.formatMessage({
                  id: "general.cancel",
                  defaultMessage: "Cancel"
                })}
              </Button>
            )}
            {!isCheckedReadOnly && (
              <Button
                color="primary"
                sx={{ fontSize: "16px" }}
                disabled={!validPointsPerQuestion}
                onClick={() => {
                  submission.is_checked ? updateFeedback() : submitFeedback();
                  setShowSubmissionDialog(false);
                }}>
                {intl.formatMessage({
                  id: submission.is_checked
                    ? "feedback.updateFeedback"
                    : "feedback.sendFeedback",
                  defaultMessage: "Send feedback"
                })}
              </Button>
            )}
            {isCheckedReadOnly && (
              <Button
                onClick={() => {
                  setShowSubmissionDialog(false);
                  dispatch(sendFeedback(false));
                }}
                color="primary"
                sx={{ fontSize: "16px" }}>
                {intl.formatMessage({
                  id: "general.close",
                  defaultMessage: "Close"
                })}
              </Button>
            )}
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

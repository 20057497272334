import React, { Suspense } from "react";
import { Routes, Route } from "react-router";
import * as Sentry from "@sentry/react";
import { PangeaSpinner } from "../components/SharedComponents";

import TaskStatsView from "../components/Tasks/Stats/TaskStatsView";
import Task from "../components/Tasks/Task";

const SentryRoutes = Sentry.withSentryReactRouterV7Routing(Routes);

export default function TaskRoutes() {
  return (
    <SentryRoutes>
      <Route index element={<Task />} />
      <Route path="stats" element={<TaskStatsView />} />
    </SentryRoutes>
  );
}

// Dependencies
import React, { useRef, useState, useEffect } from "react";
import { auth, httpCallables } from "../../../firebase";
import { injectIntl } from "react-intl";
import { useNavigate } from "react-router";
import { useQuery } from "../../../hooks";
import { mapConcepts, calculateFindInTextScore } from "./utils";
import { isEmpty } from "lodash-es";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import {
  selectCurrentText,
  setSelectedTextId,
  setTextUrl
} from "../../../redux/textsSlice";
import { selectSubmission, selectTask } from "../../../redux/tasksSlice";
import { selectCourseByTaskId } from "../../../redux/coursesSlice";
import { submitFeedback as setSubmitFeedback } from "../../../redux/taskSlice";
import { selectSubmissionQuestions } from "../../../redux/interactionsSlice";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";

// Components
import TaskFeedbackSidebar from "./TaskFeedbackSidebar";
import FeedbackScreen from "./FeedbackScreen";
import Sidebar from "../../../Sidebar";

import { Box } from "@mui/material";
import PangeaSpinner from "../../SharedComponents/PangeaSpinner";
import FeedbackSubmissionModal from "./FeedbackSubmissionModal";

function TaskFeedback({ intl }) {
  // Hooks
  const dispatch = useDispatch();
  let { submission_id } = useQuery();

  // Redux state
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const task = useSelector((state) => selectTask(state, submission.task_id));

  const text = useSelector(selectCurrentText);
  const questions = useSelector(selectSubmissionQuestions);
  const currentQuestionId = useSelector(
    (state) => state.interactions.selectedInteractionId
  );
  const highlights = useSelector((state) => state.interactions.highlights);
  const answers = useSelector((state) => state.interactions.answers);
  const feedbacks = useSelector((state) => state.interactions.feedbacks);

  const isSubmitFeedback = useSelector((state) => state.task.submitFeedback);

  const course = useSelector((state) =>
    selectCourseByTaskId(state, submission.task_id)
  );
  // Ephemeral state
  const [isLoading, setIsLoading] = useState(false);
  const [conceptMapping, setConceptMapping] = useState([]);
  const [highlightMatch, setHighlightMatch] = useState(0);
  const [showSubmissionDialog, setShowSubmissionDialog] = useState(false);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);

  // Derived state
  const isChecked = submission.is_checked;
  const currentUser = auth.currentUser;

  // Behavior

  useEffect(() => {
    let parts = [];
    parts.push({
      course: course.id,
      url: "/tasks",
      text: intl.formatMessage({
        id: "appBar.tasks",
        defaultMessage: "Assignments"
      })
    });

    course.name &&
      parts.push({
        course: course.id,
        url: `/tasks?course_id=${course.id}`,
        text: course.name
      });

    task.name &&
      parts.push({
        course: course.id,
        url: `/tasks?course_id=${task.course_id}&task_id=${task.id}&submission_id=${submission_id}`,
        text: task.name
      });

    parts.push({
      course: course.id,
      url: `/task?submission_id=${submission_id}`,
      text: intl.formatMessage({
        id: "feedback.breadcrumb",
        defaultMessage: "Feedback"
      })
    });
    dispatch(setBreadcrumbs({ breadcrumbs: parts, blue: true }));
  }, [
    currentUser.uid,
    dispatch,
    intl,
    submission.owner,
    submission_id,
    task.course_id,
    task.id,
    task.name,
    task.student,
    submission.user_name,
    course.name,
    course.id
  ]);

  useEffect(() => {
    if (!task.text_id) return;
    dispatch(setSelectedTextId(task.text_id));
  }, [dispatch, task.text_id]);

  useEffect(() => {
    if (!text.course_id || !text.file_url) return;
    httpCallables
      .textFunctions({
        func_name: "getTextDownloadUrl",
        courseId: text.course_id,
        fileUrl: text.file_url,
        textId: text.id
      })
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setTextUrl({ url: response.data.payload, text_id: text.id })
          );
        }
      });
  }, [dispatch, text.course_id, text.file_url]);

  useEffect(() => {
    if (!currentQuestionId) return;

    const answer = answers.find(
      (answer) => answer.interaction_id === currentQuestionId
    );

    const question = questions.find(
      (question) => question.id === currentQuestionId
    );

    const conceptMap = mapConcepts(question, answer);
    setConceptMapping(conceptMap);
  }, [answers, currentQuestionId, questions]);

  useEffect(() => {
    if (!currentQuestionId) return;

    const question = questions.find(
      (question) => question.id === currentQuestionId
    );
    const studentHighlights = highlights.filter(
      (highlight) => highlight.interaction_id === currentQuestionId
    );

    const score = calculateFindInTextScore(question, studentHighlights);
    setHighlightMatch(score);
  }, [currentQuestionId, highlights, questions]);

  useEffect(() => {
    setShowSubmissionDialog(isChecked);
  }, [isChecked]);

  useEffect(() => {
    if (isSubmitFeedback) {
      setShowSubmissionDialog(true);
    }
    dispatch(setSubmitFeedback(false));
  }, [dispatch, isSubmitFeedback]);

  //Render
  if (isLoading || !answers || isEmpty(task)) {
    return <PangeaSpinner />;
  } else
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%"
        }}>
        <Sidebar>
          <TaskFeedbackSidebar
            editable={task.creator === currentUser.uid && !isChecked}
            onQuestionIndex={setSelectedQuestionIndex}
            //TODO: isTeacher and showMatch are mostly doing the same thing, check if we can get rid of showMatch
            isTeacher={course.course_role === "Teacher"}
            showMatch={course.course_role === "Teacher"}
            selectedQuestionIndex={selectedQuestionIndex}
            task={task}
            questions={questions}
            highlights={highlights}
            highlightMatch={highlightMatch}
          />
        </Sidebar>
        <FeedbackScreen
          task={task}
          selectedQuestionIndex={selectedQuestionIndex}
          showMatch={task.creator === currentUser.uid}
          isTeacher={task.creator === currentUser.uid}
          onQuestionIndex={setSelectedQuestionIndex}
          conceptMapping={conceptMapping}
          highlightMatch={highlightMatch}
        />
        {showSubmissionDialog && (
          <FeedbackSubmissionModal
            showSubmissionDialog={showSubmissionDialog}
            setShowSubmissionDialog={setShowSubmissionDialog}
            feedbacks={feedbacks}
            setIsLoading={setIsLoading}
          />
        )}
      </Box>
    );
}

export default injectIntl(TaskFeedback);

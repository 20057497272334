// Dependencies
import React, { useEffect, useState, useLayoutEffect } from "react";
import { httpCallables } from "../../../firebase";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router";

import { captureException } from "../../../utils/errorHandlers";
import { useQuery } from "../../../hooks";
import useCourseId from "../../../hooks/useCourseId";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  setTasks,
  setSubmissions,
  selectTask
} from "../../../redux/tasksSlice";
import { setInteractions } from "../../../redux/interactionsSlice";
import { selectCourse } from "../../../redux/coursesSlice";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

// Components
import { ScrollBox, PangeaSpinner } from "../../SharedComponents";
import CreateAssignment from "./CreateAssignment";

const useStyles = makeStyles((theme) => {
  return {
    readerViewContainer: {
      position: "relative",
      width: "100%",
      minHeight: "calc(100vh - 120px)"
    },
    drawerContainer: {
      position: "relative",
      width: "50%",
      maxWidth: "2000px",
      minHeight: "calc(100% - 120px)",
      margin: "0 auto",
      paddingBottom: theme.spacing(2),
      gridColumn: " 5 / 15 "
    },
    dirLtr: {
      direction: "ltr"
    }
  };
});

function CreateAssignmentScreen() {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const { task_id } = useQuery();
  const courseId = useCourseId();
  const [taskId, setTaskId] = useState(task_id);
  const task = useSelector((state) => selectTask(state, Number(taskId)));
  const course = useSelector((state) => selectCourse(state, Number(courseId)));
  const { pathname } = useLocation();

  // Ephemeral State
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //Variables
  useEffect(() => {
    if (!courseId && !pathname.endsWith("edit")) {
      navigate("/tasks");
      return;
    }

    // this should happen the the router level
    if (!course.course_role) return;
    if (course.course_role === "Student")
      navigate(`/tasks?course_id=${courseId}`, { replace: true });
  });

  useLayoutEffect(() => {
    refreshTask();
  }, [dispatch, task_id]);

  const refreshTask = () => {
    let isCancelled = false;

    if (task_id) {
      setIsLoading(true);
      httpCallables
        .taskFunctions({
          func_name: "readTask",
          task_id: Number(task_id),
          teacher: true
        })
        .then(({ data }) => {
          if (!isCancelled) {
            const { task, submissions, interactions } = JSON.parse(data);
            dispatch(setTasks([task]));
            dispatch(setSubmissions(submissions));
            dispatch(setInteractions(interactions));
          }
        })
        .catch((err) => {
          captureException(err, `Failed fetch task draft. taskId ${task_id}`);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    return () => {
      isCancelled = true;
    };
  };

  if (isLoading) return <PangeaSpinner />;

  return (
    <ScrollBox className={clsx(classes.readerViewContainer, classes.dirLtr)}>
      <Box className={classes.drawerContainer} id="drawer-container">
        <CreateAssignment
          task={task}
          setTaskId={setTaskId}
          isDirty={isDirty}
          setIsDirty={setIsDirty}
          refreshTask={refreshTask}
        />
      </Box>
    </ScrollBox>
  );
}

export default CreateAssignmentScreen;

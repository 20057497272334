// Dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { grAPI } from "../../../api";
import { useGetTheme, useQuery } from "../../../hooks";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectTask,
  selectSubmission,
  submitStandardTask
} from "../../../redux/tasksSlice";
import {
  selectQuestions,
  selectSubmitValidation
} from "../../../redux/interactionsSlice";
import { setIsLoading } from "../../../redux/readerActionsSlice";
import { selectCurrentText } from "../../../redux/textsSlice";

// Components

// Material UI
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Divider
} from "@mui/material";
import { selectTextDirection } from "../../../redux/firestoreSelectors";
import { TASK, USER_TYPE } from "../../../consts";

// Styles
const useStyles = makeStyles((theme) => ({
  modal: {
    position: "relative",
    width: "50%",
    paddingBlock: theme.spacing(2)
  },
  dialogTitle: {
    paddingBlock: 0,
    marginBottom: theme.spacing(3),
    paddingInline: theme.spacing(3)
  },
  dialogContent: {
    padding: 0,
    marginBottom: theme.spacing(1.5)
  },
  modalCancel: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer"
  },
  modalActions: {
    paddingInline: theme.spacing(3),
    paddingBlock: 0,
    justifyContent: "flex-end",
    "& button": {
      fontSize: "inharit",
      fontWeight: "400",
      color: theme.palette.primary.main,
      textTransform: "uppercase"
    }
  },
  buttonDiscard: {
    "& > .MuiButton-label": {
      opacity: 0.6
    }
  },
  validationList: {
    listStyle: "none",
    textAlign: "left",
    margin: 0,
    padding: 0,
    "& > li": {
      marginBottom: theme.spacing(1),
      paddingInline: theme.spacing(1),
      paddingBlock: theme.spacing(1),
      display: "flex",
      "&:last-child": {
        marginBottom: 0
      },
      "&:nth-child(even)": {
        backgroundColor: "rgba(0,0,0,0.1)"
      }
    }
  },
  validationListQuestion: {
    width: "50%"
  },
  spacer: {
    width: theme.spacing(2)
  },
  validationListStatus: {
    width: "50%",
    color: theme.palette.primary.main
  },
  dialogPaper: {
    textAlign: "start",
    unicodeBidi: "plaintext"
  },
  dialogPaperWarning: {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary
  },
  checkbox: {
    paddingInline: theme.spacing(3)
  },
  dialogMessage: {
    whiteSpace: "pre-line",
    lineHeight: "24px",
    marginBottom: theme.spacing(1.5),
    paddingInline: theme.spacing(3)
  }
}));

export default function SubmitTaskModal({
  modalVisible,
  setModalVisible,
  afterSubmit = () => {}
}) {
  //Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const intl = useIntl();
  const theme = useGetTheme({ alwase: "light" });
  const { submission_id } = useQuery();

  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const task = useSelector((state) => selectTask(state, submission.task_id));
  const selectedText = useSelector(selectCurrentText);
  const textDirection = useSelector((state) => selectTextDirection(state));
  const questions = useSelector((state) => state.interactions.questions);
  const validation = useSelector(
    (state) => selectSubmitValidation(state) || []
  );
  const highlights = useSelector((state) => state.interactions.highlights);
  const answers = useSelector((state) => state.interactions.answers);
  const [copyInteractionsToReader, setCopyInteractionsToReader] =
    useState(true);

  const userQuestions = questions.filter(
    (q) => q.user_type === USER_TYPE.STUDENT
  );

  const closeModal = () => {
    setModalVisible(false);
  };

  async function handleSubmit() {
    setModalVisible(false);
    dispatch(setIsLoading(true));
    if (task.task_type === "guidedReading") {
      await grAPI.submitGrTask(
        submission.id,
        selectedText.id,
        task.id,
        task.course_id,
        questions,
        answers
      );
      dispatch(setIsLoading(false));
    } else {
      dispatch(
        submitStandardTask({
          text_id: task.text_id,
          submission_id: submission.id,
          copyInteractionsToReader,
          highlights,
          answers,
          questions
        })
      );
    }
  }
  function generateMessage() {
    const maxLength = 32;
    let lineNum = 1;
    if (!questions.length && task?.task_type !== "guidedReading") return "";
    let msg = validation.reduce((acc, item, i) => {
      if (!item.highlight) {
        acc = `${acc} ${lineNum++}. ${intl.formatMessage({
          id: "task.submission.supportingEvidence",
          defaultMessage: "Supporting evidence in question"
        })} "${
          questions[i].content?.length < maxLength
            ? questions[i].content
            : questions[i].content?.substring(0, maxLength) + "..."
        }"\n`;
      }
      if (!item.answer) {
        acc = `${acc} ${lineNum++}. ${intl.formatMessage({
          id: "task.submission.questionResponse",
          defaultMessage: "Your response in question"
        })} "${
          questions[i].content?.length < maxLength
            ? questions[i].content
            : questions[i].content?.substring(0, maxLength) + "..."
        }"\n`;
      }
      return acc;
    }, "");
    if (!msg && task?.task_type === "guidedReading" && !userQuestions.length) {
      msg = intl.formatMessage({
        id: "task.submission.missingGrQuestions",
        defaultMessage: "Missing questions in Guided Reading task"
      });
    }
    msg = msg.length
      ? intl.formatMessage({
          id: "task.submission.incompleteAssignment",
          defaultMessage:
            "It seems like you're about to submit an incomplete assignment. " +
            "Are you sure you want to proceed? Take a moment to review the following:"
        }) +
        "\n" +
        msg
      : intl.formatMessage({
          id: "task.submission.complete",
          defaultMessage: "Are you ready to submit your assignment?"
        });
    return <Typography className={classes.dialogMessage}>{msg}</Typography>;
  }

  const highlightIncomplete = validation.find((item) => !item.highlight);
  const ansewerIncomplete = validation.find((item) => !item.answer);
  const questionIncomplete =
    task?.task_type === "guidedReading" && !userQuestions.length;

  // Render
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Dialog
          open={modalVisible}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            "data-testid": "submit-task-dialog",
            className: classes.modal,
            style: {
              direction: textDirection
            }
          }}
          classes={{
            paper: clsx(classes.dialogPaper, {
              [classes.dialogPaperWarning]:
                highlightIncomplete || ansewerIncomplete || questionIncomplete
            })
          }}>
          <DialogTitle
            id="alert-dialog-title"
            data-testid="submit-task-dialog-title"
            classes={{ root: classes.dialogTitle }}>
            <FormattedMessage
              id={
                highlightIncomplete || ansewerIncomplete || questionIncomplete
                  ? "task.submission.incomplete"
                  : "task.submission.taskSubmission"
              }
              defaultMessage={
                highlightIncomplete || ansewerIncomplete || questionIncomplete
                  ? "Incomplete Assignment"
                  : "Assignment submission"
              }
            />
          </DialogTitle>
          <DialogContent
            classes={{ root: classes.dialogContent }}
            data-testid="submit-task-dialog-content">
            {generateMessage()}
            <Divider />
            {!questionIncomplete && (
              <FormGroup className={classes.checkbox}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={copyInteractionsToReader}
                      onChange={() => {
                        setCopyInteractionsToReader(!copyInteractionsToReader);
                      }}
                    />
                  }
                  label={intl.formatMessage({
                    id: "task.submission.copyInteractionsToReader",
                    defaultMessage:
                      "All questions and highlights will be copied to reader"
                  })}
                />
              </FormGroup>
            )}
          </DialogContent>
          <DialogActions className={classes.modalActions}>
            <Button onClick={closeModal} className={classes.buttonDiscard}>
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
            <Button onClick={handleSubmit}>
              <FormattedMessage
                id="task.submission.submit"
                defaultMessage="Submit"
              />
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

SubmitTaskModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  validationStatue: PropTypes.func,
  afterSubmit: PropTypes.func,
  taskId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

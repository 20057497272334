import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CloseIcon from "@mui/icons-material/Close";
import IntroMailDatePicker from "./IntroMailDatePicker.jsx";
import store from "../../../../redux/store.js";
import { addSnackbar } from "../../../../redux/snackbarSlice.js";
import { doc, setDoc } from "firebase/firestore";
import { useFirestoreDoc } from "reactfire";
import { firestore, httpCallables } from "../../../../firebase.js";
import { useDispatch, useSelector } from "react-redux";
import { RestartAlt } from "@mui/icons-material";
import { TooltipWithIntl } from "../../../SharedComponents/index.js";
import { format, max, addHours } from "date-fns";
import { selectCourse } from "../../../../redux/coursesSlice.js";
import { setIsOpen } from "../../../../redux/emailModalSlice.js";
import { marked } from "marked";
import CreateInitialData from "./consts.js";

const IntroductionEmailModal = () => {
  const theme = useTheme();
  const intl = useIntl();

  const selectedCourseId = useSelector(
    (state) => state.user.userProfile.selectedCourseId
  );
  const course = useSelector((state) =>
    selectCourse(state, Number(selectedCourseId))
  );
  const isEmailModalOpen = useSelector((state) => state.emailModal.isOpen);
  const dispatch = useDispatch();
  const initialData = CreateInitialData(course.name);
  const [subject, setSubject] = useState(initialData.subject);
  const [content, setContent] = useState(initialData.content);
  const [scheduleTime, setScheduleTime] = useState(initialData.scheduleTime);
  const [mailSent, setMailSent] = useState(initialData.mailSent);
  const introEmailRef = doc(firestore, "introEmail", `${selectedCourseId}`);
  const { status, data: snapshot } = useFirestoreDoc(introEmailRef);
  const showRestartButton =
    (subject !== initialData.subject || content !== initialData.content) &&
    !mailSent;
  const contentMaxRows = 13;
  const timestampToDate = (timestamp) =>
    timestamp ? timestamp.toDate() : null;
  const formatDate = (date) =>
    date ? format(date, "MMM d, yyyy 'at' h a") : null;
  const onRestart = () => {
    setSubject(initialData.subject);
    setContent(initialData.content);
  };
  const emailData = {
    subject: subject,
    content: content,
    scheduleTime: scheduleTime,
    mailSent: mailSent,
    course_id: `${selectedCourseId}`
  };
  const disclaimer =
    "\n\n\n*This Email was written and sent by the instructor.*";
  const onClose = () => {
    saveNewData({
      subject: subject,
      content: content,
      scheduleTime: scheduleTime,
      mailSent: mailSent,
      course_id: `${selectedCourseId}`
    });
    dispatch(setIsOpen(false));
  };

  // fetch data from firestore or create new doc if it doesn't exist.
  useEffect(() => {
    if (status === "loading") return;
    if (snapshot.data()) {
      setSubject(snapshot.data().subject);
      setContent(snapshot.data().content);
      setScheduleTime(timestampToDate(snapshot.data().scheduleTime));
      setMailSent(snapshot.data().mailSent);
    } else {
      saveNewData({ ...initialData, course_id: `${selectedCourseId}` });
    }
  }, [snapshot]);

  const saveNewData = (data) => {
    setDoc(introEmailRef, data);
  };

  const generateFooterMessage = () => {
    if (snapshot?.data()?.mailSent) {
      return `Email sent`;
    } else if (scheduleTime) {
      return `Email will be sent on ${formatDate(scheduleTime)}`;
    } else {
      return ``;
    }
  };

  const handleSendNow = () => {
    const data = {
      course_id: selectedCourseId,
      subject: subject,
      content: marked(content + disclaimer)
    };
    httpCallables
      .coursesFunctions({
        func_name: "sendEmailToCourseUsers",
        ...data
      })
      .then((response) => {
        const { success } = response.data;
        if (success) {
          saveNewData({
            subject: subject,
            content: content,
            scheduleTime: null,
            mailSent: true,
            course_id: `${selectedCourseId}`
          });
        }
      });
    setMailSent(true);
    dispatch(setIsOpen(false));
    store.dispatch(
      addSnackbar({
        message: intl.formatMessage({
          id: "tasks.introEmailSent",
          defaultMessage: "Email sent"
        }),
        actions: [
          {
            intlId: "showme",
            intlDefaultMsg: "Show Me",
            callBack: "showIntroEmailModal"
          }
        ]
      })
    );
  };

  return (
    <Dialog
      open={isEmailModalOpen}
      PaperProps={{
        style: {
          width: "700px",
          borderRadius: "12px",
          padding: "13px 24px"
        }
      }}
      onClose={onClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle sx={{ padding: "0px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px"
          }}>
          <Typography variant="h6">Introduction email</Typography>
          <Box>
            {showRestartButton && (
              <TooltipWithIntl
                defaultMessage="Reset subject and content"
                intlStringId="tasks.restartIntroEmail"
                placement="top">
                <IconButton
                  onClick={onRestart}
                  size="small"
                  color="inherit"
                  aria-label="Restart subject and content">
                  <RestartAlt />
                </IconButton>
              </TooltipWithIntl>
            )}
            <TooltipWithIntl
              defaultMessage="Close"
              intlStringId="general.close"
              placement="top">
              <IconButton
                onClick={onClose}
                size="small"
                color="inherit"
                aria-label="Close">
                <CloseIcon />
              </IconButton>
            </TooltipWithIntl>
          </Box>
        </Box>
        <Divider variant="fullWidth" sx={{ marginInline: "-24px" }} />
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "28px",
            "& .Mui-disabled": {
              WebkitTextFillColor: theme.palette.text.secondary
            }
          }}>
          <TextField
            id="subject"
            aria-label="email subject"
            label="Subject"
            fullWidth
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            disabled={mailSent}
          />
          <TextField
            id="content"
            aria-label="email content"
            label="Content"
            fullWidth
            value={content}
            onChange={(e) => setContent(e.target.value)}
            multiline
            disabled={mailSent}
            rows={contentMaxRows}
            maxRows={contentMaxRows}
          />
        </Box>
      </DialogContent>
      {!mailSent && (
        <DialogActions sx={{ paddingInline: "24px" }}>
          <Button
            onClick={handleSendNow}
            variant="contained"
            color="primary"
            size="medium">
            Send Now
          </Button>
          <Typography>Or</Typography>
          <Box>
            <IntroMailDatePicker
              minDate={max([
                new Date(course.course_start),
                addHours(new Date(), 48)
              ])}
              maxDate={new Date(course.course_end)}
              data={emailData}
              saveNewData={saveNewData}
            />
          </Box>
        </DialogActions>
      )}
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        sx={{
          color: "text.secondary",
          fontWeight: "bold",
          textAlign: "right",
          paddingInlineEnd: "24px"
        }}>
        {generateFooterMessage()}
      </Typography>
    </Dialog>
  );
};

export default IntroductionEmailModal;

// Dependencies
import React, { useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { firestore } from "../../../firebase";
import { standardTaskAPI, tasksAPI } from "../../../api";
import { format, max } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import useGetTheme from "../../../hooks/useGetTheme";
import { doc, setDoc } from "firebase/firestore";

// Redux Dependencies
import { useSelector } from "react-redux";

//Components
import TaskStatusChip from "./TaskStatusChip";
import DeadlineDatePicker from "./DeadlineDatePicker";
import TooltipWithIntl from "../../SharedComponents/tooltip/TooltipWithIntl";

// Mui
import makeStyles from "@mui/styles/makeStyles";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import {
  Link,
  Chip,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  DialogTitle,
  Dialog,
  DialogContentText,
  DialogActions,
  Button,
  DialogContent
} from "@mui/material";
import { USER_TYPE } from "../../../consts";

// Styles
const useStyles = makeStyles((theme) => ({
  tableContainer: {
    paddingLeft: "10%"
  },
  tableRow: {
    // Equelize the height of the table rows with and without action buttons
  },
  pointer: {
    cursor: "pointer"
  },
  chip: {
    margin: "-8px",
    cursor: "pointer"
  },
  missed: {
    backgroundColor: "#f9413e"
  },
  late: {
    backgroundColor: "#fcb63f"
  },
  pending: {
    border: "1px solid black"
  },
  submitted: {
    backgroundColor: "transparent",
    color: "#0eac72",
    border: "1px solid #0eac72"
  },
  graded: {
    backgroundColor: "#0eac72"
  },
  sent: {
    backgroundColor: theme.palette.primary,
    color: theme.palette.text.primary
  },
  add: {
    border: `1px solid ${theme.palette.primary}`,
    color: theme.palette.primary,
    width: "fit-content"
  },
  actions: {
    paddingRight: 0,
    display: "flex",
    justifyContent: "flex-end"
  },
  link: {
    color: theme.palette.text.primary
  },
  actionIcon: {
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  grade: {
    textAlign: "center"
  },
  studentStatsButton: {
    marginTop: theme.spacing(0.5)
  },
  teacherSubmission: {
    background: theme.palette.background.studentView
  },
  teacherActions: {
    height: "58px"
  }
}));

export default function SubmissionDetails({
  submission,
  course,
  task,
  graders,
  setModalVisible
}) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const navigate = useNavigate();
  const theme = useGetTheme();
  // Redux State
  const currentUser = useSelector((state) => state.user.auth.uid);
  const isImpersonating = useSelector(
    (state) => state.user.original_auth !== -1
  );

  const formattedSubmissionDate = submission.submission_date
    ? format(
        toZonedTime(
          submission.submission_date,
          Intl.DateTimeFormat().resolvedOptions().timeZone
        ),
        "MMM do h:mm a"
      )
    : null;

  // Ephemeral State
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [resubmissionDialogOpen, setResubmissionDialogOpen] = useState(false);

  // Variables
  const add = intl.formatMessage({
    id: "tasks.feedback.add",
    defaultMessage: "add"
  });
  const sent = intl.formatMessage({
    id: "tasks.feedback.sent",
    defaultMessage: "Sent"
  });
  const isPersonalDueDate =
    new Date(submission.due_date).getTime() !==
    new Date(task.original_due_date).getTime();

  // Behavior

  // Display the status chips

  function getFeedback(submission) {
    if (submission.is_checked) {
      return (
        <Chip
          color="primary"
          className={classes.chip}
          label={sent}
          size="small"
        />
      );
    } else {
      if (submission.submission_date) {
        return (
          <Chip
            color="primary"
            variant="outlined"
            className={classes.chip}
            label={add}
            size="small"
            tabIndex={0}
            role="button"
            aria-label={`Action chip: ${add}`}
            sx={{
              "&:hover": {
                backgroundColor: theme.palette.card.background.hover
              }
            }}
          />
        );
      } else return null;
    }
  }

  const wrapLink = (submission, value) => {
    if (
      submission.submission_date &&
      (submission.is_checked ||
        !graders ||
        !graders[submission.submission_id] ||
        graders[submission.submission_id].grader === currentUser)
    ) {
      let linkTarget = `/task?submission_id=${submission.id}`;

      return (
        <Link
          className={classes.link}
          component={RouterLink}
          to={linkTarget}
          underline="hover">
          {value}
        </Link>
      );
    } else return value;
  };

  const navigateToSubmission = (submission, studentView = false) => {
    if (submission.owner === currentUser && studentView) {
      navigate(`/task?submission_id=${submission.id}&type=student`);
    } else if (submission.is_checked) {
      navigate(`/task?submission_id=${submission.id}`);
    } else if (submission.submission_date) {
      if (
        graders &&
        graders[submission.id] &&
        graders[submission.id].grader !== currentUser
      ) {
        setModalVisible(submission);
      } else {
        const docRef = doc(
          firestore,
          `taskGraders/${course.id}/graders/${submission.id}`
        );
        !isImpersonating && setDoc(docRef, { grader: currentUser });
        navigate(`/task?submission_id=${submission.id}`);
      }
    }
  };

  function handleDeadlineChange(submission, newDeadline) {
    tasksAPI.updateDueDateForStudent(submission, newDeadline);
  }

  const isTeacher = (submission) => {
    return submission.role?.toUpperCase() === USER_TYPE.TEACHER.toUpperCase();
  };

  const renderResubmissionDialog = () => {
    const resubmitSubmission = async () => {
      standardTaskAPI.allowResubmitSubmission(
        submission.id,
        submission.owner,
        task.name
      );
      setResubmissionDialogOpen(false);
    };

    return (
      <Dialog
        open={resubmissionDialogOpen}
        onClose={() => setResubmissionDialogOpen(false)}>
        <DialogTitle>
          {intl.formatMessage({
            id: "tasks.manager.resubmitDialotTitle",
            defaultMessage: "Allow Assignment Resubmission"
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {intl.formatMessage(
              {
                id: "tasks.manager.resubmitDialotBody",
                defaultMessage:
                  "Are you sure you want to allow student to resubmit their assignment? Please note, the previous submission will be overwritten."
              },
              { name: submission.user_name }
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setResubmissionDialogOpen(false)}
            color="primary">
            {intl.formatMessage({
              id: "general.cancel",
              defaultMessage: "Cancel"
            })}
          </Button>
          <Button onClick={resubmitSubmission} color="primary" autoFocus>
            {intl.formatMessage({
              id: "tasks.manager.resubmitDialotAction",
              defaultMessage: "Allow Resubmission"
            })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Render
  return (
    <TableRow
      key={submission.submission_id}
      className={clsx(
        classes.tableRow,
        submission.submission_date && classes.pointer,
        isTeacher(submission) && classes.teacherSubmission
      )}>
      <TableCell component="th" scope="row">
        <Link
          className={classes.link}
          onClick={() => {
            navigateToSubmission(submission, true);
          }}
          underline="hover"
          tabIndex="0"
          role="button"
          aria-label={`Navigate to submission by ${submission.user_name}`}
          onKeyDown={(event) => {
            if (event.key === "Enter" || event.key === " ") {
              navigateToSubmission(submission, true);
            }
          }}>
          <Typography component="span" variant="body2">
            {submission.user_name}
          </Typography>
        </Link>
      </TableCell>

      <TableCell>
        <TaskStatusChip
          task={task}
          submission={submission}
          userRole={course.course_role}
          onClick={() => {
            navigateToSubmission(submission);
          }}
        />
      </TableCell>

      <TableCell>{formattedSubmissionDate}</TableCell>
      <TableCell>{wrapLink(submission, getFeedback(submission))}</TableCell>
      <TableCell
        className={clsx(
          classes.actions,
          !isTeacher(submission) && classes.studentActions,
          isTeacher(submission) && classes.teacherActions
        )}>
        {submission.role !== "TEACHER" && (
          <>
            {submission.status === "Submitted" && (
              <TooltipWithIntl
                intlStringId="tasks.manager.resubmitTooltip"
                defaultMessage="Allow resubmission"
                placement="bottom">
                <IconButton
                  aria-label={"Allow resubmission"}
                  component="button"
                  onClick={() => setResubmissionDialogOpen(true)}
                  size="large">
                  <AutorenewIcon />
                </IconButton>
              </TooltipWithIntl>
            )}
            <DeadlineDatePicker
              type={"dateTime"}
              isOpen={datePickerOpen}
              setIsOpen={setDatePickerOpen}
              deadline={submission.due_date}
              minDate={max([new Date(task?.original_due_date), new Date()])}
              label="Task deadline"
              onChange={(newDueDate) =>
                handleDeadlineChange(submission, newDueDate)
              }>
              <TooltipWithIntl
                intlStringId="tasks.deadline"
                defaultMessage={
                  isPersonalDueDate
                    ? "Personal due date: " +
                      format(submission.due_date, "MMM do, yyyy")
                    : "Set personal due date "
                }
                placement="bottom">
                <IconButton
                  aria-label={"Date picker"}
                  component="button"
                  onClick={() => {
                    setDatePickerOpen(true);
                  }}
                  className={classes.taskDeadline}
                  size="large">
                  {isPersonalDueDate ? (
                    <EditCalendarOutlinedIcon />
                  ) : (
                    <CalendarTodayOutlinedIcon />
                  )}
                </IconButton>
              </TooltipWithIntl>
            </DeadlineDatePicker>
            <TooltipWithIntl
              intlStringId="analytics.studentAnalytics"
              defaultMessage="Student analytics"
              placement="bottom">
              <IconButton
                aria-label={"Student analytics"}
                className={classes.studentStatsButton}
                component="button"
                onClick={() => {
                  navigate(
                    `/reports/student?course_id=${course.id}&user_id=${submission.owner}`
                  );
                }}>
                <AssessmentIcon />
              </IconButton>
            </TooltipWithIntl>
            {resubmissionDialogOpen && renderResubmissionDialog()}
          </>
        )}
      </TableCell>
    </TableRow>
  );
}
